import classNames from 'classnames';
import React from 'react';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import TSPInfo from 'tripkit-react/dist/model/info/TSPInfo';
import RegionInfo from 'tripkit-react/dist/model/region/RegionInfo';
import TransportUtil from 'tripkit-react/dist/trip/TransportUtil';

const operatorRowPropsDefaultStyle = (theme: TKUITheme) => ({
    main: {
        ...genStyles.flex,
        ...genStyles.column,
        padding: '15px',
        // cursor: 'pointer',
        ...theme.divider,
    },
    header: {
        ...genStyles.flex,
        ...genStyles.alignCenter
    },
    body: {
        ...genStyles.flex,
        ...genStyles.alignCenter,
        marginTop: '10px'
    },
    integrations: {
        ...theme.textSizeCaption,
        ...theme.textColorGray
    },
    name: {
        ...genStyles.grow
    },
    preselected: {
        color: '#00be5e!important',
        '& path': {
            fill: '#00be5e'
        }
    },
    iconFaded: {
        opacity: '.3'
    }
});

type IStyle = ReturnType<typeof operatorRowPropsDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    operator: TSPInfo;
    checkedModes?: string[];
    regionInfo: RegionInfo;
    preselected?: boolean;
    onMouseOver: () => void;
    onMouseOut: () => void;
    onClick: () => void;
    reference?: (ref: any) => void;
}

// TODO: rename to PublicTSPRow
const OperatorRow: React.FunctionComponent<IProps> =
    ({ operator, checkedModes, regionInfo, preselected, onMouseOver, onMouseOut, onClick, reference, classes, theme }) => {
        const integrations = Array.from(Object.values(operator.modesById).reduce((acc, mode) => {
            mode.integrations.forEach(int => acc.add(int));
            return acc;
        }, new Set<string>()));
        return (
            <div className={classNames(classes.main, preselected && classes.preselected)} onMouseEnter={onMouseOver} onMouseLeave={onMouseOut} onClick={onClick} ref={reference}>
                <div className={classes.header}>
                    <div className={classes.name}>
                        {operator.name}
                    </div>
                    {operator.modes.map(mode => regionInfo.specificModes[mode.mode] &&
                        <img src={TransportUtil.getTransIcon(regionInfo.specificModes[mode.mode]!.modeInfo, { onDark: theme.isDark })} key={mode.mode} className={checkedModes && !checkedModes.includes(mode.mode) ? classes.iconFaded : undefined} />)}
                </div>
                <div className={classes.body}>
                    <div className={classes.integrations}>
                        {integrations
                            .map(integration => integration.replace("_", "-"))
                            .filter(integration => integration !== "routing")
                            .join(", ")}
                    </div>
                </div>
            </div>
        );
    }

export default withStyles(OperatorRow, operatorRowPropsDefaultStyle);