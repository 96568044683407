import React, { useContext } from 'react';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import TKUICard from 'tripkit-react/dist/card/TKUICard';
import RegionInfo from 'tripkit-react/dist/model/region/RegionInfo';
import { genStyles } from 'tripkit-react';
import ModeInfo from 'tripkit-react/dist/model/trip/ModeInfo';
import TSPInfo from 'tripkit-react/dist/model/info/TSPInfo';
import PTOperatorsView from './PTOperatorsView';
import RoutesView from './RoutesView';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import RouteInfo from 'tripkit-react/dist/model/info/RouteInfo';
import { AppContext } from '../app/CityStatsApp';

export const tabsStyle = (theme: TKUITheme, color?: string) => ({
    '& .MuiToggleButtonGroup-root': {
        margin: '5px'
    },
    '& .MuiToggleButton-root': {
        ...genStyles.grow,
        padding: '5px',
        textTransform: 'initial',
        ...genStyles.borderRadius(8),
        color: black(1, theme.isDark),
        borderColor: black(3, theme.isDark),
        '&.Mui-selected': {
            background: 'none!important',
            borderColor: color ?? theme.colorPrimary,
            color: color ?? theme.colorPrimary
        },
        '&:not(.Mui-selected):hover': {
            background: 'none',
            borderColor: black(2, theme.isDark),
            color: black(0, theme.isDark)
        }
    }
});

const pTDetailsViewPropsDefaultStyle = (theme: TKUITheme) => ({
    main: {
        ...genStyles.flex,
        ...genStyles.column,
        height: '100%',
        minHeight: '1px',
        ...tabsStyle(theme)
    },
    subtitle: {
        lineHeight: '36px',
        marginTop: '5px'
    }
});

type IStyle = ReturnType<typeof pTDetailsViewPropsDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    mode: ModeInfo;
    operators?: TSPInfo[];
    routes?: RouteInfo[];
    checkedModes: string[];
    regionInfo?: RegionInfo;
    operatorFilter?: string;
    onOperatorFilterChange?: (filter: string) => void;
    onRequestClose?: () => void;
}

export enum Sections {
    Operators = "Operators",
    Routes = "Routes"
}

const PTDetailsView: React.FunctionComponent<IProps> = props => {
    const { mode, onRequestClose, classes } = props;
    const { pTSection: section, setPTSection: setSection, routesFilter, setRoutesFilter, setCheckedShapeTypes } = useContext(AppContext);
    const tabs =
        <ToggleButtonGroup value={section} exclusive onChange={(event, newSection) => newSection && setSection(newSection)} aria-label="text formatting">
            <ToggleButton value={Sections.Operators} disableFocusRipple disableTouchRipple>
                {Sections.Operators}
            </ToggleButton>
            <ToggleButton value={Sections.Routes} disableFocusRipple disableTouchRipple>
                {Sections.Routes}
            </ToggleButton>
        </ToggleButtonGroup>;
    return (
        <TKUICard
            title={mode.alt}
            subtitle={<span style={{ fontSize: '14px' }}>Use ↑ / ↓ to browse, ↵ to select, <span style={{ fontFamily: 'monospace' }}>esc</span> to go back</span>}
            onRequestClose={onRequestClose}
            scrollable={false}
        >
            <div className={classes.main}>
                {tabs}
                {section === Sections.Operators ?
                    <PTOperatorsView {...props} filter={props.operatorFilter} onFilterChange={props.onOperatorFilterChange} /> :
                    <RoutesView {...props} filter={routesFilter} setFilter={setRoutesFilter} />}
            </div>
        </TKUICard>
    );
}

export default withStyles(PTDetailsView, pTDetailsViewPropsDefaultStyle);