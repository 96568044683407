import React, { useContext, useEffect, useState } from 'react';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import OperatorRow from './OperatorRow';
import RegionInfo from 'tripkit-react/dist/model/region/RegionInfo';
import { genStyles } from 'tripkit-react';
import { filterInputStyleBuilder } from './CitiesListView';
import ModeInfo from 'tripkit-react/dist/model/trip/ModeInfo';
import TSPInfo from 'tripkit-react/dist/model/info/TSPInfo';
import CheckboxTree, { Node } from 'react-checkbox-tree';
import { ReactComponent as IconAngleDown } from "tripkit-react/dist/images/ic-angle-down.svg.js";
import { ReactComponent as IconChecked } from "../images/ic-checked.svg";
import { ReactComponent as IconUnchecked } from "../images/ic-unchecked.svg";
import Util from 'tripkit-react/dist/util/Util';
import TransportUtil from 'tripkit-react/dist/trip/TransportUtil';
import classnames from 'classnames';
import { AppContext } from '../app/CityStatsApp';
import ModeIdentifier from 'tripkit-react/dist/model/region/ModeIdentifier';
import { getInitCheckedModes } from './StatsView';
import { useAppClasses } from '../app/app.jss';

const pTOperatorsViewPropsDefaultStyle = (theme: TKUITheme) => ({
    main: {
        ...genStyles.flex,
        ...genStyles.column,
        height: '100%',
        minHeight: '1px'
    },
    listPanel: {
        ...genStyles.scrollableY,
        ...genStyles.flex,
        ...genStyles.column,
        ...genStyles.grow,
        borderTop: '1px solid ' + black(4, theme.isDark)
    },
    topSplit: {
        ...genStyles.flex,
        padding: '10px',
        ...genStyles.grow,
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        // height: '1px', // Use this when not in a Split
        height: '100%'  // Use this if in a Split
    },
    chartSplit: {
        ...genStyles.flex,
        padding: '10px',
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        '&>*>*>*': {
            height: '100%'
        },
        height: '100%' // Use this if in a Split
    },
    filterInput: filterInputStyleBuilder(theme),
    filterPanel: {
        margin: '16px 8px 8px 8px'
    },
    iconMode: {
        height: '20px',
        width: '20px!important'
    },
    subtitle: {
        lineHeight: '36px',
        marginTop: '5px'
    }
});

type IStyle = ReturnType<typeof pTOperatorsViewPropsDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    mode: ModeInfo;
    operators?: TSPInfo[];
    checkedModes: string[];
    regionInfo?: RegionInfo;
    filter?: string;
    onFilterChange?: (filter: string) => void;
}

let inputRefGlobal;
const focusInputEventListener = (e: KeyboardEvent) => {
    if (inputRefGlobal && document.activeElement !== inputRefGlobal && (e.keyCode === 38 || e.keyCode === 40)) {
        inputRefGlobal?.focus();
    }
};

// TODO: rename to PublicTSPsView
const PTOperatorsView: React.FunctionComponent<IProps> =
    ({ operators, regionInfo, filter, onFilterChange, classes, theme }) => {
        const { checkedModes, setCheckedModes, preselectedOperator, setPreselectedOperator, setSelectedOperatorId } = useContext(AppContext);
        let inputRef: HTMLElement | undefined = undefined;
        const rowRefs: HTMLElement[] = [];
        useEffect(() => {
            inputRef?.focus();
        }, []);
        // Give focus to filter input on key down / up if it doesn't have it.
        useEffect(() => {
            window.addEventListener('keydown', focusInputEventListener);
            return () => window.removeEventListener('keydown', focusInputEventListener);
        }, []);
        const appClasses = useAppClasses(theme);
        const [expanded, setExpanded] = useState<string[]>([]);
        const filterNodes = [{
            value: 'filters',
            label: "Filters",
            // icon: <div />,
            className: appClasses.checkboxNode,
            children: regionInfo?.modes[ModeIdentifier.PUBLIC_TRANSPORT_ID].specificModes.concat(regionInfo?.modes[ModeIdentifier.PUBLIC_TRANSIT_LIMITED_ID]?.specificModes ?? []).reduce((children, smode) => {
                children.push({
                    value: smode.modeInfo.identifier ?? smode.title,
                    label: Util.toFirstUpperCase(smode.title),
                    icon: <img src={TransportUtil.getTransIcon(smode.modeInfo, { onDark: theme.isDark })} className={classes.iconMode} />,
                    className: appClasses.checkboxNode
                });
                return children;
            }, [] as Node[])
        }]
        const allModeIds = filterNodes[0].children?.map(c => c.value);
        return (
            <div className={classes.main}>
                <div className={classes.filterPanel}>
                    <CheckboxTree
                        nodes={filterNodes}
                        checked={checkedModes}
                        // Use getInitCheckedModes, which includes all modes, to preserve modes order, required to detect if equals or not to the default in StateUrl.getUrlFromState()
                        onCheck={update => regionInfo && setCheckedModes?.(getInitCheckedModes(regionInfo).filter(mode => !allModeIds?.includes(mode) || update.includes(mode)))}
                        expanded={expanded}
                        onExpand={setExpanded}
                        onlyLeafCheckboxes={true}
                        expandOnClick={true}
                        onClick={() => { }}
                        icons={{
                            expandOpen: <IconAngleDown />,
                            expandClose: <IconAngleDown />,
                            check: <IconChecked />,
                            halfCheck: <IconChecked />,
                            uncheck: <IconUnchecked />,
                            parentOpen: null,
                            parentClose: null
                        }}
                    />
                </div>
                <input
                    type="text"
                    spellCheck={false}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    className={classes.filterInput}
                    placeholder={"Search"}
                    // To avoid the warning: A component is changing an uncontrolled input of type text to be controlled. Input elements should not switch from uncontrolled to controlled (or vice versa)
                    value={filter ?? ""}
                    onChange={e => onFilterChange?.(e.target.value ?? undefined)}
                    ref={(el: any) => {
                        inputRef = el;
                        inputRefGlobal = el;
                    }}
                    onKeyDown={e => {
                        if (operators && (e.keyCode === 38 || e.keyCode === 40)) { // up / down arrows
                            const i = preselectedOperator ? operators?.indexOf(preselectedOperator) : -1;
                            const newI = ((e.keyCode === 38 ? i - 1 : i + 1) + operators.length) % operators.length;
                            setPreselectedOperator?.(operators[newI]);
                            (rowRefs[newI] as any)?.scrollIntoViewIfNeeded?.();
                            e.preventDefault();
                        } else if ((e.keyCode === 13 || e.keyCode === 9) && preselectedOperator) { // return or tab
                            setSelectedOperatorId?.(preselectedOperator.id);
                        }
                    }}
                />
                <div className={classes.listPanel}>
                    {regionInfo && operators?.map((operator, i) =>
                        <OperatorRow
                            operator={operator}
                            checkedModes={checkedModes}
                            regionInfo={regionInfo}
                            preselected={operator === preselectedOperator}
                            key={operator.id}
                            onMouseOver={() => {
                                setPreselectedOperator?.(operator);
                            }}
                            onMouseOut={() => {
                                setPreselectedOperator?.(undefined);
                            }}
                            onClick={() => {
                                setSelectedOperatorId?.(operator.id);
                            }}
                            reference={(el: any) => rowRefs[i] = el}
                        />
                    )}
                </div>
            </div>
        );
    }

export default withStyles(PTOperatorsView, pTOperatorsViewPropsDefaultStyle);