import React from 'react';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import RegionInfo from 'tripkit-react/dist/model/region/RegionInfo';
import Util from 'tripkit-react/dist/util/Util';
import TSPInfo, { TSPInfoMode } from 'tripkit-react/dist/model/info/TSPInfo';
import TransportUtil from 'tripkit-react/dist/trip/TransportUtil';
import { Integration, integrationValues } from '../app/CityStatsApp';
import { Bar } from 'react-chartjs-2';
import { getModesHierarchy } from './StatsView';
import ModeIdentifier from 'tripkit-react/dist/model/region/ModeIdentifier';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const overviewChartDefaultStyle = (theme: TKUITheme) => ({
    main: {
        width: '100%',
        height: '100%',
        ...genStyles.flex
    },
    leftPanel: {
        ...genStyles.flex,
        height: '100%',
        boxSizing: 'border-box',
        '& > *': {
            ...genStyles.grow
        }
    },
    topSplit: {
        ...genStyles.flex,
        padding: '10px',
        ...genStyles.grow,
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        // height: '1px', // Use this when not in a Split
        height: '100%'  // Use this if in a Split
    },
    chartSplit: {
        ...genStyles.flex,
        padding: '10px',
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        '&>*>*>*': {
            height: '100%'
        },
        height: '100%' // Use this if in a Split
    }
});

type IStyle = ReturnType<typeof overviewChartDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    regionInfo: RegionInfo;
    operators: TSPInfo[];
    checkedModes: string[];
    expandedModes: string[];
    checkedIntegrations: Integration[];
}

const integrationToColor = (integration: Integration) => {
    switch (integration) {
        case "routing": return "pink";
        case "real_time": return "lightblue";
        case "bookings": return "orange";
        default: return "lightgreen"
    }
}

export const isSubMode = (m1: string, m2: string) =>
    TransportUtil.isSubMode(m1, m2) ||
    m2 === "pr" && !TransportUtil.isSubMode(m1, "pt");  // Model that any non PT mode is a submode of artificial mode "pr" (Private transport)

export const commonChartsOptions = (theme: TKUITheme) => {
    return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: black(1, theme.isDark)
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: black(1, theme.isDark)
                },
                grid: {
                    color: black(3, theme.isDark)
                }
            },
            y: {
                ticks: {
                    color: black(1, theme.isDark)
                },
                grid: {
                    color: black(3, theme.isDark)
                }
            }
        }
    };
}

const OverviewChart: React.FunctionComponent<IProps> = (props: IProps) => {
    const { regionInfo, operators, checkedModes, expandedModes, checkedIntegrations, theme } = props;
    const hModesToInfos = hmodes =>
        hmodes.reduce((acc, hnode) => {
            acc.push(hnode.mode.modeInfo);
            hnode.children && acc.push(...hModesToInfos(hnode.children));
            return acc;
        }, []);
    const modesHierarchy = getModesHierarchy(regionInfo);
    const chartModes = hModesToInfos(modesHierarchy);
    const matchesIntegration = (op: TSPInfoMode, integ: string) => op.integrations?.includes(integ);
    const chartAuxData = chartModes
        .filter(chMode => checkedModes.includes(chMode.identifier!) || checkedModes.some(checkedMode => isSubMode(checkedMode, chMode.identifier!)))
        .filter(chMode => expandedModes.some(eMode => isSubMode(chMode.identifier, eMode))
            || modesHierarchy.some(mNode => mNode.mode.modeInfo.identifier === chMode.identifier))
        .map(mode => {
            if (mode.identifier!.startsWith(ModeIdentifier.PUBLIC_TRANSIT_ID)) {
                const modeOperators = operators.filter(op => op.modes.some(opMode => isSubMode(opMode.mode, mode.identifier!)));
                const integrationToNumOfOperators = modeOperators.reduce((acc, modeOp) => {
                    integrationValues.forEach(integ =>
                        modeOp.modes.some(opMode => isSubMode(opMode.mode, mode.identifier!) && matchesIntegration(opMode, integ))
                        && (acc[integ] = (acc[integ] ?? 0) + 1));
                    return acc;
                }, {});
                return ({
                    mode: mode,
                    integrationToNumOfOperators
                })
            }
            const integrationToNumOfOperators =
                (Object.values(regionInfo.specificModes) as any[])
                    .concat(Object.values(regionInfo.modes).filter(mode => mode.specificModes.length === 0)) // Also consider general modes that have no children, as ps_tax.
                    .reduce((acc, sMode) => {
                        isSubMode(sMode.modeInfo.identifier!, mode.identifier)
                            && (sMode.integrations ?? ["routing"]).forEach(integ => acc[integ] = (acc[integ] ?? 0) + 1); // If no integrations array assume just routing integrations (e.g. ps_tax).
                        return acc;
                    }, {});
            return ({
                mode: mode,
                integrationToNumOfOperators
            })
        });
    const labels = chartAuxData.map(item => Util.toFirstUpperCase(item.mode.alt));
    const chartData = {
        labels,
        datasets: checkedIntegrations.map(integ => ({
            label: Util.toFirstUpperCase(integ),
            data: chartAuxData.map(item => item.integrationToNumOfOperators[integ] ?? 0),
            backgroundColor: integrationToColor(integ)
        }))
    };
    const options = commonChartsOptions(theme);
    options["title"] = {
        display: true,
        text: 'Number of Transport Providers by Type & Integration',
        color: black(1, theme.isDark)
    };
    return (
        <Bar options={options} data={chartData} />
    );
};

export default withStyles(OverviewChart, overviewChartDefaultStyle);