import classNames from 'classnames';
import React from 'react';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import RegionInfo, { Mode, SpecificMode } from 'tripkit-react/dist/model/region/RegionInfo';
import TransportUtil from 'tripkit-react/dist/trip/TransportUtil';

const privateTSPRowPropsDefaultStyle = (theme: TKUITheme) => ({
    main: {
        ...genStyles.flex,
        ...genStyles.column,
        padding: '15px',
        // cursor: 'pointer',
        ...theme.divider,
    },
    header: {
        ...genStyles.flex,
        ...genStyles.alignCenter,
        '& img': {
            marginLeft: '10px'
        }
    },
    body: {
        ...genStyles.flex,
        ...genStyles.column,
        ...genStyles.center,
        marginTop: '10px'
    },
    integrations: {
        ...theme.textSizeCaption,
        ...theme.textColorGray
    },
    name: {

    },
    preselected: {
        color: '#00be5e!important',
        '& path': {
            fill: '#00be5e'
        }
    },
    iconFaded: {
        opacity: '.3'
    },
    modeImages: {
        ...genStyles.flex,
        ...genStyles.alignCenter,
        marginLeft: 'auto'
    }
});

type IStyle = ReturnType<typeof privateTSPRowPropsDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    operator: SpecificMode | Mode;
    regionInfo: RegionInfo;
    preselected?: boolean;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    reference?: (ref: any) => void;
}

const PrivateTSPRow: React.FunctionComponent<IProps> =
    ({ operator, regionInfo, preselected, onMouseOver, onMouseOut, reference, classes, theme }) => {
        const integrations = operator instanceof SpecificMode ? operator.integrations : ["routing"];
        return (
            <div className={classNames(classes.main, preselected && classes.preselected)} onMouseEnter={onMouseOver} onMouseLeave={onMouseOut} ref={reference}>
                <div className={classes.header}>
                    <div className={classes.name}>
                        {operator.title}
                    </div>
                    {operator instanceof SpecificMode && operator.modeInfo.remoteIcon && operator.modeInfo.remoteIconIsBranding &&
                        <img src={TransportUtil.getTransIcon(operator.modeInfo, { onDark: theme.isDark })} />}
                    <div className={classes.modeImages}>
                        {operator instanceof SpecificMode ? operator.modeImageNames.map((imgName, j) =>
                            <img
                                src={TransportUtil.getTransportIconLocal(imgName, false, theme.isDark)}
                                aria-hidden={true}
                                key={j}
                            />
                        ) : <img src={TransportUtil.getTransIcon(operator.modeInfo, { onDark: theme.isDark })} />}
                    </div>
                </div>
                <div className={classes.body}>
                    <div className={classes.integrations}>
                        {integrations
                            .map(integration => integration.replace("_", "-"))
                            .filter(integration => integration !== "routing")
                            .join(", ")}
                    </div>
                </div>
            </div>
        );
    }

export default withStyles(PrivateTSPRow, privateTSPRowPropsDefaultStyle);