import React from 'react';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import TSPInfo from 'tripkit-react/dist/model/info/TSPInfo';
import { Integration } from '../app/CityStatsApp';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { commonChartsOptions } from './OverviewChart';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const pTServicesChartDefaultStyle = (theme: TKUITheme) => ({
    main: {
        width: '100%',
        height: '100%',
        ...genStyles.flex
    },
    leftPanel: {
        ...genStyles.flex,
        height: '100%',
        boxSizing: 'border-box',
        '& > *': {
            ...genStyles.grow
        }
    },
    topSplit: {
        ...genStyles.flex,
        padding: '10px',
        ...genStyles.grow,
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        // height: '1px', // Use this when not in a Split
        height: '100%'  // Use this if in a Split
    },
    chartSplit: {
        ...genStyles.flex,
        padding: '10px',
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        '&>*>*>*': {
            height: '100%'
        },
        height: '100%' // Use this if in a Split
    }
});

type IStyle = ReturnType<typeof pTServicesChartDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    operators: TSPInfo[];
    checkedModes: string[];
    checkedIntegrations: Integration[];
}

const PTServicesChart: React.FunctionComponent<IProps> = (props: IProps) => {
    const { operators, checkedModes, checkedIntegrations, theme } = props;
    const options = commonChartsOptions(theme);
    const labels = operators.map(operator => operator.name);
    const data = {
        labels,
        datasets: [
            {
                label: 'Number of services',
                data: operators.map(operator => operator.modes
                    .filter(mode => !checkedModes || checkedModes.includes(mode.mode))
                    .reduce((acc, mode) => acc + mode.numberOfServices, 0)),
                backgroundColor: 'rgba(255, 99, 132, 0.5)'
            }
        ]
    };
    return (
        <Bar options={options} data={data} />
    );
};

export default withStyles(PTServicesChart, pTServicesChartDefaultStyle);