import React, { useContext, useEffect } from 'react';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { overrideClass, TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme, white } from 'tripkit-react/dist/jss/TKUITheme';
import TKUICard from 'tripkit-react/dist/card/TKUICard';
import { resetStyles } from 'tripkit-react/dist/css/ResetStyle.css';
import classNames from 'classnames';
import DeviceUtil, { BROWSER } from 'tripkit-react/dist/util/DeviceUtil';
import { genStylesJSS } from 'tripkit-react';
import RouteInfo from 'tripkit-react/dist/model/info/RouteInfo';
import RouteRow from './RouteRow';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton/ToggleButton';
import { tabsStyle } from './PTDetailsView';
import { AppContext } from '../app/CityStatsApp';
import Color from 'tripkit-react/dist/model/trip/Color';

export function defaultRouteColor(theme: TKUITheme): Color {
    return Color.createFromHex(theme.isDark ? '#808080' : '#000000');
}

export const filterInputStyleBuilder = theme => ({
    ...resetStyles.input,
    margin: '8px',
    border: '1px solid ' + black(3, theme.isDark),
    height: '36px',
    padding: '6px 12px!important',
    ...genStyles.noShrink,
    ...genStyles.borderRadius(8),
    ...theme.textColorDefault,
    '&::placeholder': {
        ...theme.isDark && theme.textColorGray
    }
});

const routeDetailViewPropsDefaultStyle = (theme: TKUITheme) => ({
    main: props => ({
        ...genStyles.flex,
        ...genStyles.column,
        height: '100%',
        ...tabsStyle(theme, (props.route.routeColor ?? defaultRouteColor(theme)).toHex())
    }),
    listPanel: {
        ...genStyles.scrollableY,
        ...genStyles.flex,
        ...genStyles.column,
        ...genStyles.grow,
        borderTop: '1px solid ' + black(4, theme.isDark),
        padding: '10px 20px'
    },
    stop: {
        ...genStyles.flex,
        '&:first-child $line:first-child': {
            background: 'none'
        },
        '&:last-child $line:last-child': {
            background: 'none'
        }
    },
    selected: {
        color: props => (props.route.routeColor ?? defaultRouteColor(theme)).toHex(),
        '& $circle': {
            width: '20px',
            height: '20px',
            margin: '-10px'
        }
    },
    linePanel: {
        ...genStyles.flex,
        ...genStyles.column,
        ...genStyles.center,
        ...genStyles.alignCenter,
        ...genStyles.noShrink
    },
    line: {
        ...genStyles.grow,
        width: '16px',
        backgroundColor: props => (props.route.routeColor ?? defaultRouteColor(theme)).toHex(),
    },
    circle: {
        backgroundColor: white(0),
        ...genStyles.borderRadius(50, '%'),
        width: '16px',
        height: '16px',
        margin: '-8px 0',
        zIndex: 1,
        '& div': {
            ...genStyles.borderRadius(50, '%'),
            border: props => '4px solid ' + (props.route.routeColor ?? defaultRouteColor(theme)).toHex(),
            width: '100%',
            height: '100%'
        }
    },
    rightLabel: {
        padding: '12px 16px'
    }
});

type IStyle = ReturnType<typeof routeDetailViewPropsDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    route: RouteInfo;
    onRequestClose?: () => void;
}

let inputRefGlobal;
const focusInputEventListener = (e: KeyboardEvent) => {
    if (inputRefGlobal && document.activeElement !== inputRefGlobal && (e.keyCode === 38 || e.keyCode === 40)) {
        inputRefGlobal?.focus();
    }
};

const RouteDetailView: React.FunctionComponent<IProps> =
    ({ route, onRequestClose, classes }) => {
        const { selectedRouteDirectionId, setSelectedRouteDirectionId, selectedRouteStopId, setSelectedRouteStopId } = useContext(AppContext);
        let inputRef: HTMLElement | undefined = undefined;
        const rowRefs: HTMLElement[] = [];
        useEffect(() => {
            inputRef?.focus();
        }, []);
        // Give focus to filter input on key down / up if it doesn't have it.
        useEffect(() => {
            window.addEventListener('keydown', focusInputEventListener);
            if (selectedRouteDirectionId === undefined) {
                setSelectedRouteDirectionId(route.directions[0].id);
            }
            return () => window.removeEventListener('keydown', focusInputEventListener);
        }, []);
        const sortedDirections = route.directions.sort((d1, d2) => d1.id.localeCompare(d2.id));
        useEffect(() => {
            if (selectedRouteStopId) {
                const stopI = sortedDirections.find(dir => dir.id === selectedRouteDirectionId)?.stops.findIndex(stop => stop.stopCode === selectedRouteStopId);
                (stopI && rowRefs[stopI] as any)?.scrollIntoViewIfNeeded?.();
            }
        }, [selectedRouteStopId]);
        const directionTabs = (
            <ToggleButtonGroup
                value={selectedRouteDirectionId}
                exclusive
                onChange={(event, newSection) => {
                    newSection && setSelectedRouteDirectionId(newSection);
                }}
                aria-label="text formatting"
            >
                {sortedDirections.map(dir => (
                    <ToggleButton value={dir.id} disableFocusRipple disableTouchRipple key={dir.id}>
                        {dir.name}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        );
        return (
            <TKUICard
                title={route.routeName || route.shortName}
                subtitle={<span style={{ fontSize: '14px' }}>Use <span style={{ fontFamily: 'monospace' }}>esc</span> to go back</span>}
                // Card should not scroll at body, but at panel below filter, on body content. Next is just required
                // for Safari so div below filter can actually get a height and scroll happens there.
                styles={{
                    main: overrideClass({
                        ...genStylesJSS.borderRadius(0)
                    }),
                    ...DeviceUtil.browser === BROWSER.SAFARI && {
                        body: overrideClass({ height: '100%' })
                    },
                    subHeader: {
                        padding: '0 5px'
                    }
                }}
                renderSubHeader={() => <RouteRow route={route} />}
                onRequestClose={onRequestClose}
            >
                <div className={classes.main}>
                    {directionTabs}
                    <div className={classes.listPanel}>
                        {sortedDirections.find(dir => dir.id === selectedRouteDirectionId)?.stops.map((stop, i) => {
                            return (
                                <div
                                    onClick={() => setSelectedRouteStopId?.(stop.stopCode)}
                                    className={classNames(classes.stop, stop.stopCode === selectedRouteStopId && classes.selected)}
                                    key={stop.stopCode}
                                    ref={(el: any) => rowRefs[i] = el}
                                >
                                    <div className={classes.linePanel}>
                                        <div className={classes.line} />
                                        <div className={classes.circle}>
                                            <div />
                                        </div>
                                        <div className={classes.line} />
                                    </div>
                                    <div className={classes.rightLabel}>
                                        {stop.name}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </TKUICard>
        );
    }

export default withStyles(RouteDetailView, routeDetailViewPropsDefaultStyle);