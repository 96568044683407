import React, { useContext, useEffect, useState } from 'react';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import RegionInfo from 'tripkit-react/dist/model/region/RegionInfo';
import { genStyles, resetStyles } from 'tripkit-react';
import CheckboxTree, { Node } from 'react-checkbox-tree';
import { ReactComponent as IconAngleDown } from "tripkit-react/dist/images/ic-angle-down.svg.js";
import { ReactComponent as IconChecked } from "../images/ic-checked.svg";
import { ReactComponent as IconUnchecked } from "../images/ic-unchecked.svg";
import Util from 'tripkit-react/dist/util/Util';
import TransportUtil from 'tripkit-react/dist/trip/TransportUtil';
import classnames from 'classnames';
import { AppContext, ShapeTypes } from '../app/CityStatsApp';
import ModeIdentifier from 'tripkit-react/dist/model/region/ModeIdentifier';
import { getInitCheckedModes } from './StatsView';
import { useAppClasses } from '../app/app.jss';
import RouteRow from './RouteRow';
import RouteInfo from 'tripkit-react/dist/model/info/RouteInfo';
import { ReactComponent as IconRemove } from '../images/ic-cross.svg';
import { ReactComponent as IconShapeDetailed } from '../images/ic-shape-detailed.svg';
import { ReactComponent as IconShapeNotDetailed } from '../images/ic-shape-not-detailed.svg';
import TGTooltip from '../../../common/TGTooltip';
import { ReactComponent as IconHelp } from '../images/ic-help.svg';
import { ReactComponent as IconTick } from '../../../images/ic-tick.svg';

export const filterInputStyleBuilder = theme => ({
    ...genStyles.flex,
    ...genStyles.alignCenter,
    margin: '8px',
    border: '1px solid ' + black(3, theme.isDark),
    height: '36px',
    padding: '6px 12px!important',
    ...genStyles.noShrink,
    ...genStyles.borderRadius(8),
    ...theme.textColorDefault,
    '& input': {
        ...resetStyles.input,
        ...genStyles.grow,
        ...theme.textColorDefault,
        '&::placeholder': {
            ...theme.isDark && theme.textColorGray
        }
    },
    '& button': {
        ...resetStyles.button,
        width: '20px',
        height: '20px',
        padding: '5px',
        '& svg': {
            width: '100%',
            height: '100%',
            ...theme.textColorGray,
            '& path': {
                fill: 'currentColor'
            }
        }
    }
});

const routesViewPropsDefaultStyle = (theme: TKUITheme) => ({
    main: {
        ...genStyles.flex,
        ...genStyles.column,
        height: '100%',
        minHeight: '1px'
    },
    listPanel: {
        ...genStyles.scrollableY,
        ...genStyles.flex,
        ...genStyles.column,
        ...genStyles.grow,
        borderTop: '1px solid ' + black(4, theme.isDark),
        '& > *': {
            ...theme.divider
        }
    },
    topSplit: {
        ...genStyles.flex,
        padding: '10px',
        ...genStyles.grow,
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        // height: '1px', // Use this when not in a Split
        height: '100%'  // Use this if in a Split
    },
    chartSplit: {
        ...genStyles.flex,
        padding: '10px',
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        '&>*>*>*': {
            height: '100%'
        },
        height: '100%' // Use this if in a Split
    },
    filterInput: filterInputStyleBuilder(theme),
    filterPanel: {
        margin: '16px 8px 8px 8px'
    },
    filterBody: {
        margin: '5px 0 0 20px'
    },
    iconHelp: {
        height: '18px',
        width: '18px!important',
        border: '1px solid #212a33a6',
        ...genStyles.borderRadius(50, '%'),
        padding: '3px',
        '& path': {
            fill: black(1)
        }
    },
    buttonTick: {
        height: '24px!important',
        width: '24px!important',
        '& path': {
            fill: black(1)
        }
    },
    iconMode: {
        height: '20px',
        width: '20px!important'
    },
    iconShapeType: {
        height: '16px',
        width: '16px!important',
        '& path': {
            fill: black(0, theme.isDark)
        }
    },
    subtitle: {
        lineHeight: '36px',
        marginTop: '5px'
    },
    numberOfResults: {
        ...theme.textColorGray,
        margin: '0px 14px 5px auto',
        fontStyle: 'italic'
    }
});

type IStyle = ReturnType<typeof routesViewPropsDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    routes?: RouteInfo[];
    regionInfo?: RegionInfo;
    filter?: string;
    setFilter: (value?: string) => void;
}

let inputRefGlobal;
const focusInputEventListener = (e: KeyboardEvent) => {
    if (inputRefGlobal && document.activeElement !== inputRefGlobal && (e.keyCode === 38 || e.keyCode === 40)) {
        inputRefGlobal?.focus();
    }
};

const INIT_LIMIT = 20;
const LIMIT_INCREMENT = 100;


const RoutesView: React.FunctionComponent<IProps> =
    ({ routes, regionInfo, filter, setFilter, classes, theme }) => {
        const { checkedModes, setCheckedModes, checkedShapeTypes, setCheckedShapeTypes, preselectedRoute, setPreselectedRoute, setPreselectedDir, setSelectedRouteId } = useContext(AppContext);
        let inputRef: HTMLElement | undefined = undefined;
        const rowRefs: HTMLElement[] = [];
        useEffect(() => {
            inputRef?.focus();
        }, []);
        // Give focus to filter input on key down / up if it doesn't have it.
        useEffect(() => {
            window.addEventListener('keydown', focusInputEventListener);
            return () => window.removeEventListener('keydown', focusInputEventListener);
        }, []);
        const appClasses = useAppClasses(theme);
        const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false);
        const [expanded, setExpanded] = useState<string[]>(["transport_types"]);
        const [shapeFilterExpanded, setShapeFilterExpanded] = useState<string[]>([]);
        const filterNodes = [{
            value: 'transport_types',
            label: "Transport types",
            // icon: <div />,
            className: appClasses.checkboxNode,
            children: regionInfo?.modes[ModeIdentifier.PUBLIC_TRANSPORT_ID].specificModes.concat(regionInfo?.modes[ModeIdentifier.PUBLIC_TRANSIT_LIMITED_ID]?.specificModes ?? []).reduce((children, smode) => {
                children.push({
                    value: smode.modeInfo.identifier ?? smode.title,
                    label: Util.toFirstUpperCase(smode.title),
                    icon: <img src={TransportUtil.getTransIcon(smode.modeInfo, { onDark: theme.isDark })} className={classes.iconMode} />,
                    className: appClasses.checkboxNode
                });
                return children;
            }, [] as Node[])
        }]
        const shapeFilterNodes = [{
            label: "Shapes",
            value: "shapes",
            className: appClasses.checkboxNode,
            children: [{
                label: "Detailed",
                value: ShapeTypes.detailed,
                icon: <IconShapeDetailed className={classes.iconShapeType} />,
                className: appClasses.checkboxNode
            },
            {
                label: "Not detailed",
                value: ShapeTypes.not_detailed,
                icon: <IconShapeNotDetailed className={classes.iconShapeType} />,
                className: appClasses.checkboxNode
            }]
        }]
        const allModeIds = filterNodes[0].children?.map(c => c.value);
        const [limit, setLimit] = useState<number>(INIT_LIMIT);
        const onScroll = (e: any) => {
            const scrollPanel = e.target;
            if (scrollPanel.scrollTop + scrollPanel.clientHeight > scrollPanel.scrollHeight - 30) {
                setLimit(prevLimit => {
                    return prevLimit + LIMIT_INCREMENT;
                });
            }
        }
        return (
            <div className={classes.main}>
                <div className={classes.filterPanel}>
                    <CheckboxTree
                        nodes={[{
                            label: "Filters",
                            value: "filters",
                            className: appClasses.checkboxNode,
                            showCheckbox: false,
                            children: []
                        }]}
                        expanded={filtersExpanded ? ["filters"] : []}
                        onExpand={value => setFiltersExpanded(value.length > 0)}
                        expandOnClick={true}
                        onClick={() => { }}
                        icons={{
                            expandOpen: <IconAngleDown />,
                            expandClose: <IconAngleDown />,
                            check: <IconChecked />,
                            halfCheck: <IconChecked />,
                            uncheck: <IconUnchecked />,
                            parentOpen: null,
                            parentClose: null
                        }}
                    />
                    {filtersExpanded &&
                        <div className={classes.filterBody}>
                            <CheckboxTree
                                nodes={filterNodes}
                                checked={checkedModes}
                                // Use getInitCheckedModes, which includes all modes, to preserve modes order, required to detect if equals or not to the default in StateUrl.getUrlFromState()
                                onCheck={update => regionInfo && setCheckedModes?.(getInitCheckedModes(regionInfo).filter(mode => !allModeIds?.includes(mode) || update.includes(mode)))}
                                expanded={expanded}
                                onExpand={setExpanded}
                                onlyLeafCheckboxes={true}
                                expandOnClick={true}
                                onClick={() => { }}
                                icons={{
                                    expandOpen: <IconAngleDown />,
                                    expandClose: <IconAngleDown />,
                                    check: <IconChecked />,
                                    halfCheck: <IconChecked />,
                                    uncheck: <IconUnchecked />,
                                    parentOpen: null,
                                    parentClose: null
                                }}
                            />
                            <CheckboxTree
                                nodes={shapeFilterNodes}
                                checked={checkedShapeTypes}
                                onCheck={update => setCheckedShapeTypes?.(update as ShapeTypes[])}
                                expanded={shapeFilterExpanded}
                                onExpand={setShapeFilterExpanded}
                                onlyLeafCheckboxes={true}
                                expandOnClick={true}
                                onClick={() => { }}
                                icons={{
                                    expandOpen: <IconAngleDown className={appClasses.iconExpand} />,
                                    expandClose: <IconAngleDown className={classnames(appClasses.iconExpand, appClasses.iconExpandClose)} />,
                                    check: <IconChecked className={appClasses.iconCheckbox} />,
                                    halfCheck: <IconChecked className={classnames(appClasses.iconCheckbox, appClasses.iconHalfChecked)} />,
                                    uncheck: <IconUnchecked className={appClasses.iconCheckbox} />,
                                    parentOpen: null,
                                    parentClose: null
                                }}
                            />
                        </div>}
                </div>
                <div className={classes.filterInput}>
                    <input
                        type="text"
                        spellCheck={false}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        placeholder={"Search"}
                        // To avoid the warning: A component is changing an uncontrolled input of type text to be controlled. Input elements should not switch from uncontrolled to controlled (or vice versa)
                        value={filter ?? ""}
                        onChange={e => setFilter(e.target.value ?? undefined)}
                        ref={(el: any) => {
                            inputRef = el;
                            inputRefGlobal = el;
                        }}
                        onKeyDown={e => {
                            if (routes && (e.keyCode === 38 || e.keyCode === 40)) { // up / down arrows
                                const i = preselectedRoute ? routes?.indexOf(preselectedRoute) : -1;
                                const newI = ((e.keyCode === 38 ? i - 1 : i + 1) + routes.length) % routes.length;
                                setPreselectedRoute?.(routes[newI]);
                                (rowRefs[newI] as any)?.scrollIntoViewIfNeeded?.();
                                e.preventDefault();
                            } else if ((e.keyCode === 13 || e.keyCode === 9) && preselectedRoute) { // return or tab
                                setSelectedRouteId?.(preselectedRoute.id);
                            }
                        }}
                    />
                    {filter ?
                        // filter.endsWith("|") ?
                        //     <button className={classes.buttonTick}>
                        //         <IconTick onClick={() => setFilter(filter.substring(0, filter.lastIndexOf("|") - 1))} />
                        //     </button> :
                        <button>
                            <IconRemove onClick={() => setFilter(undefined)} />
                        </button> :
                        <TGTooltip title="Search by name or id. Click on map to filter-in all routes passing by that coordinate.">
                            <IconHelp className={classes.iconHelp} />
                        </TGTooltip>}
                </div>
                <div className={classes.numberOfResults}>
                    {routes && `${routes.length} results`}
                </div>
                <div className={classes.listPanel} onScroll={onScroll}>
                    {
                        regionInfo && routes
                            ?.slice(0, limit)
                            .map((value, i) =>
                                <RouteRow
                                    route={value}
                                    preselected={value === preselectedRoute}
                                    key={value.id}
                                    onMouseOver={() => {
                                        setPreselectedRoute?.(value);
                                    }}
                                    onMouseOut={() => {
                                        setPreselectedRoute?.(undefined);
                                    }}
                                    setPreselectedDir={setPreselectedDir}
                                    onClick={() => {
                                        setSelectedRouteId?.(value.id);
                                    }}
                                    reference={(el: any) => rowRefs[i] = el}
                                    onAddToFilter={() => setFilter(` ${value.id} |${filter ?? ""}`)}
                                />
                            )}
                </div>
            </div >
        );
    }

export default withStyles(RoutesView, routesViewPropsDefaultStyle);