import React, { useContext, useEffect, useState } from 'react';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import TKUICard from 'tripkit-react/dist/card/TKUICard';
import RegionInfo from 'tripkit-react/dist/model/region/RegionInfo';
import { filterInputStyleBuilder } from './CitiesListView';
import ModeInfo from 'tripkit-react/dist/model/trip/ModeInfo';
import { Node } from 'react-checkbox-tree';
import Util from 'tripkit-react/dist/util/Util';
import TransportUtil from 'tripkit-react/dist/trip/TransportUtil';
import ModeLocation from 'tripkit-react/dist/model/location/ModeLocation';
import TKUIModeLocationRow from 'tripkit-react/dist/mxm/TKUIModeLocationRow';
import LocationsData from 'tripkit-react/dist/data/LocationsData';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { RoutingResultsContext } from 'tripkit-react/dist/trip-planner/RoutingResultsProvider';
import MapUtil from 'tripkit-react/dist/util/MapUtil';
import useDebounce from './usehooks';
import { AppContext } from '../app/CityStatsApp';
import ModeIdentifier from 'tripkit-react/dist/model/region/ModeIdentifier';

const shareMicroViewPropsDefaultStyle = (theme: TKUITheme) => ({
    operators: {
        ...genStyles.flex,
        ...genStyles.column,
        height: '100%'
    },
    listPanel: {
        ...genStyles.scrollableY,
        ...genStyles.flex,
        ...genStyles.column,
        ...genStyles.grow,
        borderTop: '1px solid ' + black(4, theme.isDark)
    },
    topSplit: {
        ...genStyles.flex,
        padding: '10px',
        ...genStyles.grow,
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        // height: '1px', // Use this when not in a Split
        height: '100%'  // Use this if in a Split
    },
    chartSplit: {
        ...genStyles.flex,
        padding: '10px',
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        '&>*>*>*': {
            height: '100%'
        },
        height: '100%' // Use this if in a Split
    },
    filterInput: filterInputStyleBuilder(theme),
    filterPanel: {
        margin: '16px 8px 8px 8px'
    },
    checkboxNode: {
        '& label, .rct-bare-label': {
            ...genStyles.flex,
            ...genStyles.alignCenter,
            ...genStyles.grow
        },
        '& .rct-node-icon': {
            padding: '0 4px!important'
        },
        '& .rct-title': {
            ...genStyles.grow
        },
    },
    iconExpand: {
        height: '14px',
        width: '14px'
    },
    iconExpandClose: {
        transform: 'rotate(270deg)'
    },
    iconCheckbox: {
        height: '16px!important',
        width: '16px!important'
    },
    iconHalfChecked: {
        opacity: '.5'
    },
    iconMode: {
        height: '20px',
        width: '20px!important'
    }
});

type IStyle = ReturnType<typeof shareMicroViewPropsDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    mode: ModeInfo;
    locations?: ModeLocation[];
    setLocations?: (value: ModeLocation[]) => void;
    // checkedModes: string[];
    regionInfo: RegionInfo;
    // filter?: string;
    // onFilterChange?: (filter: string) => void;
    onRequestClose?: () => void;
    // preselectOperator?: TSPInfo;
    // onPreselectOperator?: (operator: TSPInfo | undefined) => void;
}

let inputRefGlobal;
const focusInputEventListener = (e: KeyboardEvent) => {
    if (inputRefGlobal && document.activeElement !== inputRefGlobal && (e.keyCode === 38 || e.keyCode === 40)) {
        inputRefGlobal?.focus();
    }
};

const matchesFilter = (value: ModeLocation, filter: string) => {
    const filterSplit = filter.toLowerCase().split(" ");
    return filterSplit.every((filterPart: string) =>
        (value.address ?? "").toLowerCase().includes(filterPart)
    );
}

const ShareMicroView: React.FunctionComponent<IProps> =
    ({ mode, regionInfo, onRequestClose, classes }) => {
        const { map, mapAsync } = useContext(RoutingResultsContext);
        const { locations, setLocations } = useContext(AppContext);
        // const [checkedModes, setCheckedModes] = useState<string[]>(initCheckedModes);
        let inputRef: HTMLElement | undefined = undefined;
        const rowRefs: HTMLElement[] = [];
        const [filter, setFilter] = useState<string | undefined>(undefined);
        useEffect(() => {
            inputRef?.focus();
        }, []);
        const debouncedBounds = useDebounce<any>(JSON.stringify(map?.getBounds()), 500);
        const debouncedFilter = useDebounce<any>(filter, 300);
        const routingContext = useContext(RoutingResultsContext);
        useEffect(() => {
            mapAsync.then(map => {
                const bounds = map.getBounds();
                const zoom = map.getZoom()!;
                if (zoom >= 15) {
                    const { instant, remaining } = LocationsData.instance.getRequestLocationsP(regionInfo.code, 2, [mode.identifier!], bounds);
                    setLocations?.(instant.getLocations().filter(loc => MapUtil.inBBox(loc, bounds!) && matchesFilter(loc, filter ?? "")));
                    remaining.then(result => zoom >= 16 && result && setLocations?.(result.getLocations().filter(loc => MapUtil.inBBox(loc, bounds!))));
                } else {
                    setLocations?.([]);
                }
            });
        }, [debouncedBounds, debouncedFilter]);
        // Give focus to filter input on key down / up if it doesn't have it.
        useEffect(() => {
            window.addEventListener('keydown', focusInputEventListener);
            return () => window.removeEventListener('keydown', focusInputEventListener);
        }, []);
        const [expanded, setExpanded] = useState<string[]>([]);
        const filterNodes = [{
            value: 'filters',
            label: "Filters",
            // icon: <div />,
            className: classes.checkboxNode,
            children: regionInfo?.modes[ModeIdentifier.BICYCLE_SHARE_ID].specificModes.concat(regionInfo?.modes['pt_ltd']?.specificModes ?? []).reduce((children, smode) => {
                children.push({
                    value: smode.modeInfo.identifier ?? smode.title,
                    label: Util.toFirstUpperCase(smode.title),
                    icon: <img src={TransportUtil.getTransIcon(smode.modeInfo)} className={classes.iconMode} />,
                    className: classes.checkboxNode
                });
                return children;
            }, [] as Node[])
        }]
        return (
            <TKUICard
                title={mode.alt}
                subtitle={<span style={{ fontSize: '14px' }}>Use ↑ / ↓ to browse, <span style={{ fontFamily: 'monospace' }}>esc</span> to go back</span>}
                onRequestClose={onRequestClose}
            >
                <div className={classes.operators}>
                    <div className={classes.filterPanel}>
                        {/* <CheckboxTree
                            nodes={filterNodes}
                            checked={checkedModes}
                            onCheck={setCheckedModes}
                            expanded={expanded}
                            onExpand={setExpanded}
                            onlyLeafCheckboxes={true}
                            icons={{
                                expandOpen: <IconAngleDown className={classes.iconExpand} />,
                                expandClose: <IconAngleDown className={classnames(classes.iconExpand, classes.iconExpandClose)} />,
                                check: <IconChecked className={classes.iconCheckbox} />,
                                halfCheck: <IconChecked className={classnames(classes.iconCheckbox, classes.iconHalfChecked)} />,
                                uncheck: <IconUnchecked className={classes.iconCheckbox} />,
                                parentOpen: null,
                                parentClose: null
                            }}
                        /> */}
                    </div>
                    <input
                        type="text"
                        spellCheck={false}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        className={classes.filterInput}
                        placeholder={"Search"}
                        // To avoid the warning: A component is changing an uncontrolled input of type text to be controlled. Input elements should not switch from uncontrolled to controlled (or vice versa)
                        value={filter ?? ""}
                        onChange={e => setFilter?.(e.target.value ?? undefined)}
                        ref={(el: any) => {
                            inputRef = el;
                            inputRefGlobal = el;
                        }}
                    // onKeyDown={e => {
                    //     if (operators && (e.keyCode === 38 || e.keyCode === 40)) { // up / down arrows
                    //         const i = preselectOperator ? operators?.indexOf(preselectOperator) : -1;
                    //         const newI = ((e.keyCode === 38 ? i - 1 : i + 1) + operators.length) % operators.length;
                    //         onPreselectOperator?.(operators[newI]);
                    //         (rowRefs[newI] as any)?.scrollIntoViewIfNeeded?.();
                    //         e.preventDefault();
                    //     } else if ((e.keyCode === 13 || e.keyCode === 9) && preselectOperator) { // return or tab
                    //         // onSelect?.(preselect);
                    //     }
                    // }}
                    />
                    <div className={classes.listPanel}>
                        {regionInfo && locations?.map((operator, i) =>
                            <TKUIModeLocationRow
                                location={operator}
                                selected={routingContext.query?.to?.getKey() === operator.getKey()}
                                showBothIcons={true}
                                key={i}
                                onClick={() => {
                                    routingContext.onQueryUpdate(Util.iAssign(routingContext.query, {
                                        to: operator
                                    }))
                                }}
                            />
                            // <TKUIModeLocationRow
                            //     operator={operator}
                            //     checkedModes={checkedModes}
                            //     regionInfo={regionInfo}
                            //     preselected={operator === preselectOperator}
                            //     key={operator.id}
                            //     onMouseOver={() => {
                            //         onPreselectOperator?.(operator);
                            //     }}
                            //     onMouseOut={() => {
                            //         onPreselectOperator?.(undefined);
                            //     }}
                            //     reference={(el: any) => rowRefs[i] = el}
                            // />
                        )}
                    </div>
                </div>
            </TKUICard>
        );
    }

export default withStyles(ShareMicroView, shareMicroViewPropsDefaultStyle);