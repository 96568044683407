import React, { useEffect } from 'react';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { overrideClass, TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme, white } from 'tripkit-react/dist/jss/TKUITheme';
import City from 'tripkit-react/dist/model/location/City';
import TKUICard from 'tripkit-react/dist/card/TKUICard';
import { resetStyles } from 'tripkit-react/dist/css/ResetStyle.css';
import classNames from 'classnames';
import DeviceUtil, { BROWSER } from 'tripkit-react/dist/util/DeviceUtil';
import { ReactComponent as IconRightArrow } from "tripkit-react/dist/images/ic-angle-right.svg.js";
import { genStylesJSS } from 'tripkit-react';
import RefreshBtn from './RefreshBtn';
import { TRIPGO_API_CACHE_NAME } from '../cache/CacheUtil';

export const filterInputStyleBuilder = theme => ({
    ...resetStyles.input,
    margin: '8px',
    border: '1px solid ' + black(3, theme.isDark),
    height: '36px',
    padding: '6px 12px!important',
    ...genStyles.noShrink,
    ...genStyles.borderRadius(8),
    ...theme.textColorDefault,
    '&::placeholder': {
        ...theme.isDark && theme.textColorGray
    }
});

const tGUICityLocationsPropsDefaultStyle = (theme: TKUITheme) => ({
    main: {
        ...genStyles.flex,
        ...genStyles.column,
        height: '100%'
    },
    cardSubtitle: {
        ...genStyles.flex,
        ...genStyles.spaceBetween,
        ...genStyles.alignCenter,
        margin: '5px -4px 0 0',
        height: '36px'

    },
    filterInput: filterInputStyleBuilder(theme),
    listPanel: {
        ...genStyles.scrollableY,
        ...genStyles.flex,
        ...genStyles.column,
        ...genStyles.grow,
        borderTop: '1px solid ' + black(4, theme.isDark)
    },
    cityRow: {
        ...genStyles.flex,
        ...genStyles.alignCenter,
        padding: '15px',
        cursor: 'pointer',
        ...theme.divider,
        margin: '0 15px',
        borderLeft: '1px solid ' + white(0, theme.isDark),
        '& svg': {
            marginLeft: 'auto'
        },
        '& path': {
            fill: black(1)
        }
    },
    selected: {
        color: '#00be5e!important',
        '& path': {
            fill: '#00be5e'
        }
    }
});

type IStyle = ReturnType<typeof tGUICityLocationsPropsDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    values?: City[];
    onSelect?: (city: City) => void;
    preselect?: City;
    onPreselect?: (city: City | undefined) => void;
    filter?: string;
    onFilterChange?: (filter: string) => void;
    onRefreshCities?: () => void;
}

let inputRefGlobal;
const focusInputEventListener = (e: KeyboardEvent) => {
    if (inputRefGlobal && document.activeElement !== inputRefGlobal && (e.keyCode === 38 || e.keyCode === 40)) {
        inputRefGlobal?.focus();
    }
};

const TGUICityLocations: React.FunctionComponent<IProps> =
    ({ values, onSelect, filter, onFilterChange, preselect, onPreselect, onRefreshCities, classes }) => {
        const cities = values;
        let inputRef: HTMLElement | undefined = undefined;
        const rowRefs: HTMLElement[] = [];
        useEffect(() => {
            inputRef?.focus();
        }, []);
        // Give focus to filter input on key down / up if it doesn't have it.
        useEffect(() => {
            window.addEventListener('keydown', focusInputEventListener);
            return () => window.removeEventListener('keydown', focusInputEventListener);
        }, []);
        return (
            <TKUICard
                title={"Cities"}
                subtitle={
                    <div className={classes.cardSubtitle}>
                        <span style={{ fontSize: '14px' }}>Use ↑ / ↓ to browse, ↵ to select</span>
                        {onRefreshCities &&
                            <RefreshBtn
                                actions={[
                                    {
                                        label: "Refresh list",
                                        handler: async () => {
                                            const cache = await caches.open(TRIPGO_API_CACHE_NAME);
                                            (await cache.keys()).forEach(request => {
                                                request.url.match("regions.json") && cache.delete(request);
                                            });
                                            onRefreshCities();
                                        }
                                    },
                                    {
                                        label: "Refresh all",
                                        handler: async () => {
                                            const found = await caches.delete(TRIPGO_API_CACHE_NAME);
                                            found && onRefreshCities();
                                        }
                                    }
                                ]}
                                waiting={cities === undefined ? true : undefined}
                                tooltip="Refresh the list of cities. Right click to get more refresh options"
                            />}
                    </div>
                }
                // presentation={CardPresentation.SLIDE_UP}                
                // Card should not scroll at body, but at panel below filter, on body content. Next is just required
                // for Safari so div below filter can actually get a height and scroll happens there.
                styles={{
                    main: overrideClass({
                        ...genStylesJSS.borderRadius(0)
                    }),
                    ...DeviceUtil.browser === BROWSER.SAFARI && {
                        body: overrideClass({ height: '100%' })
                    }
                }}
            >
                <div className={classes.main}>
                    <input
                        type="text"
                        spellCheck={false}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        className={classes.filterInput}
                        placeholder={"Search"}
                        // To avoid the warning: A component is changing an uncontrolled input of type text to be controlled. Input elements should not switch from uncontrolled to controlled (or vice versa)
                        value={filter ?? ""}
                        onChange={e => onFilterChange?.(e.target.value ?? undefined)}
                        ref={(el: any) => {
                            inputRef = el;
                            inputRefGlobal = el;
                        }}
                        onKeyDown={e => {
                            if (cities && (e.keyCode === 38 || e.keyCode === 40)) { // up / down arrows
                                const i = preselect ? cities?.indexOf(preselect) : -1;
                                const newI = ((e.keyCode === 38 ? i - 1 : i + 1) + cities.length) % cities.length;
                                onPreselect?.(cities[newI]);
                                (rowRefs[newI] as any)?.scrollIntoViewIfNeeded?.();
                                e.preventDefault();
                            } else if ((e.keyCode === 13 || e.keyCode === 9) && preselect) { // return or tab
                                onSelect?.(preselect);
                            }
                        }}
                    />
                    <div className={classes.listPanel}>
                        {cities?.map((city, i) => {
                            return (
                                <div
                                    className={classNames(classes.cityRow, city === preselect && classes.selected)}
                                    onClick={() => {
                                        onSelect?.(city);
                                    }}
                                    onMouseOver={() => onPreselect?.(city)}
                                    onMouseOut={() => onPreselect?.(undefined)}
                                    key={city.name}
                                    ref={(el: any) => rowRefs[i] = el}
                                >
                                    {city.name}
                                    <IconRightArrow />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </TKUICard>
        );
    }

export default withStyles(TGUICityLocations, tGUICityLocationsPropsDefaultStyle);