import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'date-time-format-timezone/build/browserified/date-time-format-timezone-all-zones-no-locale-min';
import "reflect-metadata";
import React from 'react';
import ReactDOM from 'react-dom';
import CityStatsApp from './app/CityStatsApp';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// if (process.env.NODE_ENV === 'development') {
// require("../../mock/mock.js");
// }

const containerElement = document.getElementById("citystats-main") as HTMLElement;
ReactDOM.render(<CityStatsApp />, containerElement);

serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();