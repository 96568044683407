import { TKUIMapViewClass, L } from "tripkit-react/dist/map/TKUIMapView";
import MapUtil from "tripkit-react/dist/util/MapUtil";
import { FeatureCollection } from "geojson";
import { ShapeTypes } from "../app/CityStatsApp";
import RouteInfo, { RouteStop } from "tripkit-react/dist/model/info/RouteInfo";
import { defaultRouteColor } from "./RouteDetailView";
import { tKUIDeaultTheme } from "tripkit-react/dist/jss/TKUITheme";

class MapController {

    private map: TKUIMapViewClass;
    private mglMap?: any;
    private mglMapP: any;
    private mglMapPResolve?: (map: any) => void;
    private eventBus = document.createElement('div');
    public static theme = tKUIDeaultTheme({ isDark: false, isHighContrast: false });

    private data: FeatureCollection;
    private hoveredRouteIds: string[] = [];
    private selectedRoute?: RouteInfo;
    private selectedDirectionId?: string;
    private selectedRouteStop?: RouteStop;
    private routeDirectionMarkers?: L.Marker[] = undefined;

    constructor(map: TKUIMapViewClass) {
        this.map = map;
        this.data = MapUtil.featuresToCollection([]);
        this.mglMapP = new Promise<any>(resolve => {
            this.mglMapPResolve = resolve;
        })
        map.mapboxGlMapP.then(mapboxGlMap => {
            mapboxGlMap.on('load', () => {
                this.mglMap = mapboxGlMap;
                this.init();
                this.mglMapPResolve!(this.mglMap);
            });
        });
    }

    private readonly defaultColor = ["rgba", 255, 152, 0, 1];
    private readonly selectedColor = ["rgba", 57, 31, 255, 1];
    private readonly selectedDirColor = ["rgba", 0, 133, 67, 1];

    private readonly defaultLineWidth = [
        "interpolate",
        [
            "exponential",
            1.5
        ],
        [
            "zoom"
        ],
        14,
        0.5,
        18,
        12
    ];

    private readonly routesLayer = {
        'id': 'routesLayer',
        "type": "line",
        'source': 'routes',
        "layout": {
            "line-join": [
                "step",
                [
                    "zoom"
                ],
                "miter",
                14,
                "round"
            ]
        },
        "paint": {
            "line-width": [
                "interpolate",
                [
                    "exponential",
                    1.5
                ],
                [
                    "zoom"
                ],
                14,
                0.5,
                18,
                12
            ],
            "line-color": [
                'case',
                ['boolean', ['feature-state', 'hover'], false], this.selectedColor,
                this.defaultColor
            ],
            "line-dasharray": [
                "step",
                [
                    "zoom"
                ],
                [
                    "literal",
                    [
                        1,
                        0
                    ]
                ],
                15,
                [
                    "literal",
                    [
                        1.5,
                        0.4
                    ]
                ],
                16,
                [
                    "literal",
                    [
                        1,
                        0.2
                    ]
                ]
            ]
        }
    };

    private readonly selectedLineWidth = [
        "interpolate",
        [
            "exponential",
            1.5
        ],
        [
            "zoom"
        ],
        14,
        4,
        18,
        12
    ];

    private init() {
        this.mglMap.addSource('routes', {
            'type': 'geojson',
            'data': this.data
        });
        this.mglMap.addLayer(this.routesLayer);

        this.map.getLeafletMap()
            .on("click", e => {
                const latLng = (e as any).latlng;
                const point = this.mglMap.project([latLng.lng, latLng.lat]);
                const options = {
                    layers: ['routesLayer']
                };
                const features = this.mglMap.queryRenderedFeatures([point.x, point.y], options);
                const featureIDs = features.map(feature => feature.properties.routeId);
                this.fireRouteClickEvent([...new Set<string>(featureIDs)]); // Remove repeated (1 feature per direction, where all dirs of a route share the same routeId)

                if (this.mglMap.getLayer('selectedRouteLayer')) {
                    const selectedRouteFeatures = this.mglMap.queryRenderedFeatures([point.x, point.y], { layers: ['selectedRouteLayer'] });
                    selectedRouteFeatures.length > 0 && this.fireRouteDirectionClickEvent(selectedRouteFeatures[0].properties.routeId, selectedRouteFeatures[0].properties.dirId);
                }
            });;

        this.map.getLeafletMap()
            .on("mousemove", e => {
                const latLng = (e as any).latlng;
                const point = this.mglMap.project([latLng.lng, latLng.lat]);
                const options = {
                    layers: ['routesLayer']
                };
                const features = this.mglMap.queryRenderedFeatures([point.x, point.y], options);
                if (features.length > 0) {
                    this.hoveredRouteIds = features.map(feature => feature.properties.routeId);
                    this.fireRouteHoverEvent(this.hoveredRouteIds);
                } else if (this.hoveredRouteIds.length > 0) {   // Just fire cleanup event if necessary
                    this.hoveredRouteIds = [];
                    this.fireRouteHoverEvent([]);
                }
            });;

        // // When the user moves their mouse over the state-fill layer, we'll update the
        // // feature state for the feature under the mouse.
        // this.mglMap.on('mousemove', 'routesLayer', (e) => {
        //     if (e.features.length > 0) {
        //         if (this.hoveredRouteId !== undefined) {
        //             this.mglMap.setFeatureState(
        //                 { source: 'routes', id: this.hoveredRouteId },
        //                 { hover: false }
        //             );
        //         }
        //         this.hoveredRouteId = e.features[0].properties!.routeId;
        //         console.log(this.hoveredRouteId);
        //         this.mglMap.setFeatureState(
        //             { source: 'routes', id: this.hoveredRouteId },
        //             { hover: true }
        //         );
        //     }
        // });

        // // When the mouse leaves the state-fill layer, update the feature state of the
        // // previously hovered feature.
        // this.mglMap.on('mouseleave', 'routesLayer', () => {
        //     if (this.hoveredRouteId !== undefined) {
        //         this.mglMap.setFeatureState(
        //             { source: 'routes', id: this.hoveredRouteId },
        //             { hover: false }
        //         );
        //     }
        //     this.hoveredRouteId = undefined;
        // });        
    }

    public setData(data?: FeatureCollection) {
        this.data = data ?? MapUtil.featuresToCollection([]);
        this.mglMap?.getSource('routes')?.setData(this.data);
    }

    public preselectOperator(operatorID?: string) {
        // this.mglMap?.setPaintProperty('routesLayer', 'line-color',
        //     operatorId ? ["case", ["==", ["get", "operatorId"], operatorId], ["rgba", 57, 31, 255, 1], ["rgba", 57, 31, 255, .3]] : ["rgba", 57, 31, 255, .3]);
        this.mglMap?.setPaintProperty('routesLayer', 'line-color',
            operatorID ? ["case", ["==", ["get", "operatorId"], operatorID], this.selectedColor, this.defaultColor] : this.defaultColor);
        this.mglMap?.setPaintProperty('routesLayer', 'line-opacity',
            operatorID ? ["case", ["==", ["get", "operatorId"], operatorID], 1, .5] : .5);
    }

    private static createStopIcon(color: string, highlighted?: boolean) {
        return L.divIcon({
            html: `<div style="${highlighted ? "width: 18px; height: 18px; margin: -2px 0 0 -2px;" : "width: 14px; height: 14px;"} border: 3px solid ${color}; border-radius: 50%; background: white;"/>`,
            iconSize: [14, 14],
            iconAnchor: [7, 7],
            className: ""
        })
    }

    public async selectRoute(route?: RouteInfo, directionId?: string) {
        this.selectedRoute = route;
        this.selectedDirectionId = directionId;
        this.routeDirectionMarkers?.forEach(marker => marker.remove());
        this.routeDirectionMarkers = undefined;
        const mglMap = await this.mglMapP;
        if (route) {
            if (!mglMap.getLayer('selectedRouteLayer')) {
                mglMap.setLayoutProperty('routesLayer', 'visibility', 'none');
                const selectedColor = ["rgba", ["get", "routeColorR"], ["get", "routeColorG"], ["get", "routeColorB"], 1];
                mglMap.addLayer({
                    'id': 'selectedRouteLayer',
                    "type": "line",
                    'source': 'routes', // reference the data source
                    'filter': ["==", ["get", "routeId"], route.id],
                    "layout": {
                        "line-join": [
                            "step",
                            [
                                "zoom"
                            ],
                            "miter",
                            14,
                            "round"
                        ]
                    },
                    "paint": {
                        "line-width": this.selectedLineWidth,
                        "line-color": selectedColor,
                        "line-dasharray": [
                            "step",
                            [
                                "zoom"
                            ],
                            [
                                "literal",
                                [
                                    1,
                                    0
                                ]
                            ],
                            15,
                            [
                                "literal",
                                [
                                    1.5,
                                    0.4
                                ]
                            ],
                            16,
                            [
                                "literal",
                                [
                                    1,
                                    0.2
                                ]
                            ]
                        ]
                    }
                });
            }
            mglMap.setPaintProperty('selectedRouteLayer', 'line-opacity',
                directionId ? ["case", ["==", ["get", "dirId"], directionId], 1, .4] : 1);
            if (directionId) {
                const direction = route.directions.find(dir => dir.id === directionId)!;
                this.mglMapP.then(() => {
                    const stopIcon = MapController.createStopIcon((route.routeColor ?? defaultRouteColor(MapController.theme)).toHex());
                    this.routeDirectionMarkers = direction.stops.map(stop => {
                        // const marker = new MGLMarker();
                        // marker.setLngLat([stop.lng, stop.lat]).addTo(mglMap);
                        const marker = L.marker([stop.lat, stop.lng], { icon: stopIcon });
                        marker.on('click', () => this.fireRouteStopClickEvent(stop));
                        marker.addTo(this.map.getLeafletMap())
                        // .bindPopup(`<div style="padding: 5px 10px; font-size: 14px">${stop.name}</div>`, { closeButton: false, autoPan: false, offset: [0, 0] });
                        return marker;
                    });
                });
            }
        } else {
            mglMap.removeLayer('selectedRouteLayer');
            mglMap.setLayoutProperty('routesLayer', 'visibility', 'visible');
        }
    }

    public selectRouteStop(stop?: RouteStop) {
        if (!this.routeDirectionMarkers) {
            return;
        }
        const direction = this.selectedRoute!.directions.find(dir => dir.id === this.selectedDirectionId)!;
        if (this.selectedRouteStop) {
            this.routeDirectionMarkers[direction.stops.indexOf(this.selectedRouteStop)]?.setIcon(MapController
                .createStopIcon((this.selectedRoute!.routeColor ?? defaultRouteColor(MapController.theme)).toHex(), false));
        }
        this.selectedRouteStop = stop;
        stop && this.routeDirectionMarkers[direction.stops.indexOf(stop)]?.setIcon(MapController
            .createStopIcon((this.selectedRoute!.routeColor ?? defaultRouteColor(MapController.theme)).toHex(), true));
    }

    public preselectRoute(routeID?: string) {
        this.preselectRoutes(routeID ? [routeID] : []);
    }

    public preselectRoutes(routeIDs?: string[]) {
        this.mglMap?.setPaintProperty('routesLayer', 'line-color',
            ["case", ["in", ["get", "routeId"], ["array", ["literal", routeIDs]]], this.selectedColor, this.defaultColor]);
        this.mglMap?.setPaintProperty('routesLayer', 'line-opacity',
            ["case", ["in", ["get", "routeId"], ["array", ["literal", routeIDs]]], 1, .5]);
    }

    public preselectRouteDirection(routeID: string, dirID?: string) {
        this.mglMap?.setPaintProperty('routesLayer', 'line-color',
            dirID ?
                ["case",
                    ["all", ["==", ["get", "routeId"], routeID], ["==", ["get", "dirId"], dirID]], this.selectedDirColor,
                    ["==", ["get", "routeId"], routeID], this.selectedColor,
                    this.defaultColor
                ]
                : this.defaultColor);
        this.mglMap?.setPaintProperty('routesLayer', 'line-opacity',
            // dirID ? ["case", ["all", ["==", ["get", "routeId"], routeID], ["==", ["get", "dirId"], dirID]], 1, .5] : .5);
            dirID ? ["case", ["==", ["get", "routeId"], routeID], 1, .5] : .5);
    }

    private static filterExpression(filterName: string, filterValue: any): any[] {
        switch (filterName) {
            case 'operatorIDs':
                return ["in", ["get", "operatorId"], ["array", ["literal", filterValue]]];
            case 'routeIDs':
                return ["in", ["get", "routeId"], ["array", ["literal", filterValue]]];
            case 'shapeTypes':
                return ["in", ["get", "shaypeType"], ["array", ["literal", filterValue]]];
            default:
                return ["in", ["get", "mode"], ["array", ["literal", filterValue]]];
        }
    }

    public filterRoutes(filter?: { operatorIDs?: string[], routeIDs?: string[], modes?: string[], shapeTypes?: ShapeTypes[] }) {
        const filterWOUndefined = filter && { ...filter };
        filterWOUndefined && Object.keys(filterWOUndefined).forEach(key => filterWOUndefined[key] === undefined && delete filterWOUndefined[key])
        const filterExpression = !filterWOUndefined || Object.keys(filterWOUndefined).length === 0 ? undefined :
            ["all", ...Object.keys(filterWOUndefined).map(filterKey => MapController.filterExpression(filterKey, filterWOUndefined[filterKey]))];
        this.mglMapP.then(mglMap => mglMap.setFilter('routesLayer', filterExpression));
    }

    public addRouteClickListener(handler: (routeIDs: string[]) => void) {
        this.eventBus.addEventListener('routeClick', e => handler(e.detail), false);
    }

    private fireRouteClickEvent(routeIDs: string[]) {
        this.eventBus.dispatchEvent(new CustomEvent('routeClick', { detail: routeIDs }));
    }

    public addRouteHoverListener(handler: (routeIDs: string[]) => void) {
        this.eventBus.addEventListener('routeHover', e => handler(e.detail), false);
    }

    private routeHoverTarget: string[] = [];

    private fireRouteHoverEvent(routeIDs: string[]) {
        // this.routeHoverTarget = routeIDs;
        this.eventBus.dispatchEvent(new CustomEvent('routeHover', { detail: routeIDs }));
        // setTimeout(() => {
        //     if (this.routeHoverTarget === routeIDs) {
        //         this.eventBus.dispatchEvent(new CustomEvent('routeHover', { detail: routeIDs }));
        //         // console.log("trigger: " + routeIDs);
        //     } else {
        //         // console.log("filter");
        //     }
        // }, 1);
    }

    public addRouteDirectionClickListener(handler: (event: { routeId: string; directionId: string }) => void) {
        this.eventBus.addEventListener('routeDirectionClick', e => handler(e.detail), false);
    }

    private fireRouteDirectionClickEvent(routeId, directionId: string) {
        this.eventBus.dispatchEvent(new CustomEvent('routeDirectionClick', { detail: { routeId, directionId } }));
    }

    public addRouteStopClickListener(handler: (stop: RouteStop) => void) {
        this.eventBus.addEventListener('routeStopClick', e => handler(e.detail), false);
    }

    private fireRouteStopClickEvent(stop: RouteStop) {
        this.eventBus.dispatchEvent(new CustomEvent('routeStopClick', { detail: stop }));
    }

}

export default MapController;