import classnames from 'classnames';
import classNames from 'classnames';
import React from 'react';
import genStyles, { genClassNames } from 'tripkit-react/dist/css/GenStyle.css';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import RouteInfo from 'tripkit-react/dist/model/info/RouteInfo';
import TransportUtil from 'tripkit-react/dist/trip/TransportUtil';
import TGTooltip from '../../../common/TGTooltip';
import { ReactComponent as IconShapeDetailed } from '../images/ic-shape-detailed.svg';
import { ReactComponent as IconShapeNotDetailed } from '../images/ic-shape-not-detailed.svg';
import { defaultRouteColor } from './RouteDetailView';

const routeRowPropsDefaultStyle = (theme: TKUITheme) => ({
    main: {
        ...genStyles.flex,
        padding: '15px 15px 15px 10px',
        cursor: 'pointer'
    },
    right: {
        ...genStyles.grow,
        ...genStyles.column
    },
    header: {
        ...genStyles.flex,
        ...genStyles.alignCenter
    },
    body: {
        ...genStyles.flex,
        ...genStyles.column,
        marginTop: '5px'
    },
    field: {
        ...genStyles.flex,
        ...genStyles.alignCenter,
        ...theme.textColorGray,
        marginTop: '2px',
        '& > div:first-child': {
            ...theme.textWeightBold,
            color: black(0, theme.isDark),
            marginRight: '5px'
        },
        '& $iconFilter': {
            display: 'none'
        },
        '&:hover $iconFilter': {
            display: 'initial'
        }
    },
    direction: {
        ...genStyles.flex,
        ...genStyles.alignCenter,
        '& path': {
            fill: 'currentColor'
        },
        '&:hover': {
            color: theme.colorPrimary
        }
    },
    iconShapeType: {
        height: '16px',
        width: '16px!important',
        marginLeft: '10px'
    },
    name: {
        ...genStyles.grow
    },
    preselected: {
        color: '#00be5e!important',
        '& $routeColor': {
            opacity: '1'
        }
    },
    iconFaded: {
        opacity: '.3'
    },
    routeColor: {
        width: '4px',
        ...genStyles.borderRadius(3),
        marginRight: '10px',
        ...genStyles.noShrink,
        opacity: '.6'
    },
    iconFilter: {
        width: '16px',
        height: '16px',
        marginLeft: '10px',
        '& path': {
            fill: black(1)
        }
    }
});

type IStyle = ReturnType<typeof routeRowPropsDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    route: RouteInfo;
    preselected?: boolean;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    onClick?: () => void;
    setPreselectedDir?: (dirId?: string) => void;
    reference?: (ref: any) => void;
    onAddToFilter?: () => void;
}

const RouteRow: React.FunctionComponent<IProps> =
    ({ route, preselected, onMouseOver, onMouseOut, setPreselectedDir, onClick, reference, onAddToFilter, classes, theme }) => {
        return (
            <div className={classNames(classes.main, preselected && classes.preselected)} onMouseEnter={onMouseOver} onMouseLeave={onMouseOut} onClick={onClick} ref={reference}>
                <div style={{ background: (route.routeColor ?? defaultRouteColor(theme)).toHex() }} className={classes.routeColor} />
                <div className={classes.right}>
                    <div className={classes.header}>
                        <div className={classes.name}>
                            {route.routeName || route.shortName}
                        </div>
                        <img src={TransportUtil.getTransIcon(route.modeInfo, { onDark: theme.isDark })} key={route.mode} />
                    </div>
                    <div className={classes.body}>
                        <div className={classes.field}><div>Id ⋅</div>
                            {route.id}
                            {/* <IconFilter className={classes.iconFilter} onClick={() => onAddToFilter?.()} /> */}
                        </div>
                        <div className={classes.field}><div>Op ⋅</div>{route.operatorName}</div>
                        <div className={classnames(classes.field, genClassNames.alignStart)}>
                            <div>Dirs ⋅</div>
                            <div className={classnames(genClassNames.flex, genClassNames.column)}>
                                {route.directions.map(dir =>
                                    <div
                                        key={dir.id}
                                        className={classes.direction}
                                        onMouseEnter={() => setPreselectedDir?.(dir.id)}
                                        onMouseLeave={() => setPreselectedDir?.(undefined)}>
                                        {dir.name}
                                        <TGTooltip
                                            title={dir.shapeIsDetailed ? "The shape of the route direction is detailed." : "The shape of the route direction is not detailed."}
                                            placement={"right"}
                                        >
                                            {dir.shapeIsDetailed ? <IconShapeDetailed className={classes.iconShapeType} /> : <IconShapeNotDetailed className={classes.iconShapeType} />}
                                        </TGTooltip>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* {route.directions.map(dir =>
                            <div className={classes.field} key={dir.id}><div>Dir ⋅</div>{dir.name}</div>
                        )}                         */}
                        <div className={classes.field}>{`${route.numberOfServices} services`}</div>
                    </div>
                </div>
            </div>
        );
    }

export default withStyles(RouteRow, routeRowPropsDefaultStyle);