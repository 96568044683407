import React, { useContext, useEffect } from "react";
import { RegionsData } from "tripkit-react";
import ModeInfo from "tripkit-react/dist/model/trip/ModeInfo";
import { Sections } from "../cities/PTDetailsView";
import { getInitCheckedModes, getInitExpandedModes, getInitIntegrationValues, getModesHierarchy } from "../cities/StatsView";
import useDebounce from "../cities/usehooks";
import { AppContext, IAppContext, ShapeTypes } from "./CityStatsApp";

const URL_FIELD_SEPARATOR = "/";
const URL_FIELD_VALUE_SEPARATOR = ":";
const URL_VALUE_COMPONENT_SEPARATOR = "+";

interface IProps {

}
const URL_PARAM_CITY = "city";
const URL_PARAM_CHECKED_MODES = "filterModes";
const URL_PARAM_EXPANDED_MODES = "expandedModes";
const URL_PARAM_FILTER_INTEGRATIONS = "filterIntegrations";
const URL_PARAM_FILTER_SHAPE_TYPES = "filterShapeTypes";
const URL_PARAM_MODE = "mode";
const URL_PARAM_PT_SECTION = "pTSection";
const URL_PARAM_OP_SEARCH = "opSearch";
const URL_PARAM_ROUTES_SEARCH = "routesSearch";
const URL_PARAM_OPERATOR = "operator";
const URL_PARAM_OP_ROUTES_SEARCH = "opRoutesSearch";
const URL_PARAM_ROUTE = "route";
const URL_PARAM_ROUTE_DIR = "routeDir";
const URL_PARAM_ROUTE_STOP = "routeStop";

const getUrlFromState = (state: IAppContext) => {
    const { selectedCity, selectedMode, checkedModes, expandedModes, checkedIntegrations, checkedShapeTypes, operators, selectedOperatorId, operatorFilter, routesFilter, opRoutesFilter, pTSection, selectedRouteId, selectedRouteDirectionId, selectedRouteStopId } = state;
    const fieldsMap = {};
    if (selectedCity) {
        fieldsMap[URL_PARAM_CITY] = selectedCity.identifier;
    }
    if (selectedCity) {
        const regionInfo = RegionsData.instance.getRegionInfo(selectedCity.regionCode);
        const defaultCheckedModes = regionInfo && getInitCheckedModes(regionInfo);
        if (checkedModes && checkedModes.toString() !== defaultCheckedModes?.toString()) {
            fieldsMap[URL_PARAM_CHECKED_MODES] = checkedModes.toString();
        }
        const defaultExpandedModes = regionInfo && getInitExpandedModes(regionInfo);
        if (expandedModes && expandedModes.toString() !== defaultExpandedModes?.toString()) {
            fieldsMap[URL_PARAM_EXPANDED_MODES] = expandedModes.toString();
        }
        const defaultCheckedIntegrations = regionInfo && getInitIntegrationValues(operators, regionInfo);
        if (checkedIntegrations && checkedIntegrations.toString() !== defaultCheckedIntegrations?.toString()) {
            fieldsMap[URL_PARAM_FILTER_INTEGRATIONS] = checkedIntegrations.toString();
        }
        const defaultCheckedShapeTypes = Object.values(ShapeTypes);
        if (checkedShapeTypes && checkedShapeTypes.toString() !== defaultCheckedShapeTypes?.toString()) {
            fieldsMap[URL_PARAM_FILTER_SHAPE_TYPES] = checkedShapeTypes.toString();
        }
    }
    if (selectedMode) {
        fieldsMap[URL_PARAM_MODE] = selectedMode.identifier;
    }
    if (pTSection && pTSection !== Sections.Operators) {
        fieldsMap[URL_PARAM_PT_SECTION] = pTSection;
    }
    if (operatorFilter) { // Debounced by caller to avoid reflecting filter in URL on each character typed.
        fieldsMap[URL_PARAM_OP_SEARCH] = operatorFilter;
    }
    if (routesFilter) { // Debounced by caller to avoid reflecting filter in URL on each character typed.
        fieldsMap[URL_PARAM_ROUTES_SEARCH] = routesFilter;
    }
    if (selectedOperatorId) {
        fieldsMap[URL_PARAM_OPERATOR] = selectedOperatorId;
    }
    if (opRoutesFilter) { // Debounced by caller to avoid reflecting filter in URL on each character typed.
        fieldsMap[URL_PARAM_OP_ROUTES_SEARCH] = routesFilter;
    }
    if (selectedRouteId) {
        fieldsMap[URL_PARAM_ROUTE] = selectedRouteId;
    }
    if (selectedRouteDirectionId) {
        fieldsMap[URL_PARAM_ROUTE_DIR] = selectedRouteDirectionId;
    }
    if (selectedRouteStopId) {
        fieldsMap[URL_PARAM_ROUTE_STOP] = selectedRouteStopId;
    }
    let statePath = "";
    for (const field of Object.keys(fieldsMap)) {
        statePath = addField(field, fieldsMap[field]!, statePath);
    }
    return statePath;
}

const setStateFromUrl = (path: string, state: IAppContext) => {
    state.setLoadingState(true);
    const waitFor = [] as Promise<any>[];
    const fields = path.split(URL_FIELD_SEPARATOR);
    const fieldsMap = fields.reduce((acc, field) => {
        const fieldValue = field.split(URL_FIELD_VALUE_SEPARATOR);
        acc[fieldValue[0]] = fieldValue[1];
        return acc;
    }, {});
    if (fieldsMap[URL_PARAM_CITY]) {
        waitFor.push(RegionsData.instance.requireRegions().then(() => {
            const city = RegionsData.instance.getCities()?.find(city => city.identifier === fieldsMap[URL_PARAM_CITY]);
            city && state.setSelectedCity(city);
            if (city && fieldsMap[URL_PARAM_MODE]) {
                return RegionsData.instance.getRegionInfoP(city.regionCode).then(regionInfo => {
                    const modesHierarchy = getModesHierarchy(regionInfo, state.experimentalFeatures);
                    const mode = modesHierarchy
                        .reduce((smodeIDs, hMode) => {
                            smodeIDs.push(hMode.mode.modeInfo);
                            hMode.children && smodeIDs.push(...hMode.children.map(children => children.mode.modeInfo!));
                            return smodeIDs;
                        }, [] as ModeInfo[])
                        .find(modeInfo => modeInfo.identifier === fieldsMap[URL_PARAM_MODE]);
                    mode && state.setSelectedMode(mode);
                });
            }
        }));
    }
    if (fieldsMap[URL_PARAM_OPERATOR]) {
        state.setSelectedOperatorId(fieldsMap[URL_PARAM_OPERATOR]);
    }
    if (fieldsMap[URL_PARAM_OP_SEARCH]) {
        state.setOperatorFilter(decodeURI(fieldsMap[URL_PARAM_OP_SEARCH]));
    }
    if (fieldsMap[URL_PARAM_ROUTES_SEARCH]) {
        state.setRoutesFilter(decodeURI(fieldsMap[URL_PARAM_ROUTES_SEARCH]));
    }
    if (fieldsMap[URL_PARAM_OP_ROUTES_SEARCH]) {
        state.setRoutesFilter(decodeURI(fieldsMap[URL_PARAM_OP_ROUTES_SEARCH]));
    }
    if (fieldsMap[URL_PARAM_CHECKED_MODES] !== undefined) {
        state.setCheckedModes(fieldsMap[URL_PARAM_CHECKED_MODES].split(","));
    }
    if (fieldsMap[URL_PARAM_EXPANDED_MODES] !== undefined) {
        state.setExpandedModes(fieldsMap[URL_PARAM_EXPANDED_MODES].split(","));
    }
    if (fieldsMap[URL_PARAM_FILTER_INTEGRATIONS] !== undefined) {
        state.setCheckedIntegrations(fieldsMap[URL_PARAM_FILTER_INTEGRATIONS].split(","));
    }
    if (fieldsMap[URL_PARAM_FILTER_SHAPE_TYPES] !== undefined) {
        state.setCheckedShapeTypes(fieldsMap[URL_PARAM_FILTER_SHAPE_TYPES].split(","));
    }
    if (fieldsMap[URL_PARAM_PT_SECTION] !== undefined) {
        state.setPTSection(fieldsMap[URL_PARAM_PT_SECTION]);
    }
    if (fieldsMap[URL_PARAM_ROUTE] !== undefined) {
        state.setSelectedRouteId(fieldsMap[URL_PARAM_ROUTE]);
    }
    if (fieldsMap[URL_PARAM_ROUTE_DIR] !== undefined) {
        state.setSelectedRouteDirectionId(fieldsMap[URL_PARAM_ROUTE_DIR]);
    }
    if (fieldsMap[URL_PARAM_ROUTE_STOP] !== undefined) {
        state.setSelectedRouteStopId(fieldsMap[URL_PARAM_ROUTE_STOP]);
    }
    Promise.all(waitFor).then(() => state.setLoadingState(false));
}

const addField = (field: string, value: string, fieldsPath: string) => {
    return fieldsPath
        + (!fieldsPath || fieldsPath.endsWith(URL_FIELD_SEPARATOR) ? "" : URL_FIELD_SEPARATOR)
        + field + URL_FIELD_VALUE_SEPARATOR + value;
}

const StateUrl: React.FunctionComponent<IProps> = (props: IProps) => {
    const appContext = useContext(AppContext);
    const debouncedOpFilter = useDebounce<string | undefined>(appContext.operatorFilter, 300);
    const debouncedRouteFilter = useDebounce<string | undefined>(appContext.routesFilter, 300);
    const url = getUrlFromState({ ...appContext, operatorFilter: debouncedOpFilter, routesFilter: debouncedRouteFilter });
    useEffect(() => {
        setStateFromUrl(document.location.hash.substring(1), appContext);
    }, []);
    useEffect(() => {
        if (appContext.loadingState) {
            return;
        }
        window.history.replaceState(null, '', (url ? "#" + url : window.location.pathname + window.location.search));
    }, [url]);
    return null;
}

export default StateUrl;