
import { genStyles } from 'tripkit-react';
import { createUseStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';

export const appStyles = (theme: TKUITheme) => ({
    checkboxNode: {
        '& label': {
            ...genStyles.flex,
            ...genStyles.alignCenter
        },
        '& .rct-bare-label, .rct-node-clickable': {
            ...genStyles.flex,
            ...genStyles.alignCenter,
            ...genStyles.grow,
            background: 'none!important',
            cursor: 'default!important'
        },
        '& .rct-node-icon': {
            padding: '0 4px!important'
        },
        '& .rct-title': {
            ...genStyles.grow
        },
        '& .rct-collapse-btn svg': {
            height: '14px',
            width: '14px',
            '& path': {
                fill: theme.colorPrimary
            }
        },
        '&.rct-node-collapsed .rct-collapse-btn svg': {
            transform: 'rotate(270deg)'
        },
        '& .rct-checkbox svg': {
            height: '16px!important',
            width: '16px!important',
            '& path': {
                fill: theme.colorPrimary
            }
        }
    },
    iconHalfChecked: {
        opacity: '.5'
    }
});

const useAppClasses = (theme: TKUITheme) => createUseStyles(appStyles)({ theme: theme as any });

export { useAppClasses };