import { Split } from '@geoffcox/react-splitter';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import TripGoApi from 'tripkit-react/dist/api/TripGoApi';
import TKRoot from 'tripkit-react/dist/config/TKRoot';
import { TKUIConfig } from 'tripkit-react/dist/config/TKUIConfig';
import genStyles, { genClassNames } from 'tripkit-react/dist/css/GenStyle.css';
import RegionsData from 'tripkit-react/dist/data/RegionsData';
import { overrideClass, TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme, white } from 'tripkit-react/dist/jss/TKUITheme';
import TKUIMapView, { TKUIMapViewHelpers, TKUIMapViewProps } from 'tripkit-react/dist/map/TKUIMapView';
import LatLng from 'tripkit-react/dist/model/LatLng';
import City from 'tripkit-react/dist/model/location/City';
import RegionInfo, { Mode } from 'tripkit-react/dist/model/region/RegionInfo';
import { RoutingResultsContext } from 'tripkit-react/dist/trip-planner/RoutingResultsProvider';
import NetworkUtil from 'tripkit-react/dist/util/NetworkUtil';
import TGUICityLocations from '../../../coverage/TGUICityLocations';
import CitiesListView from '../cities/CitiesListView';
import StatsView from '../cities/StatsView';
import RouteInfo, { RouteStop } from 'tripkit-react/dist/model/info/RouteInfo';
import Util from 'tripkit-react/dist/util/Util';
import MapController from '../cities/MapController';
import ModeInfo from 'tripkit-react/dist/model/trip/ModeInfo';
import TSPInfo from 'tripkit-react/dist/model/info/TSPInfo';
import TransportUtil from 'tripkit-react/dist/trip/TransportUtil';
import MapUtil from 'tripkit-react/dist/util/MapUtil';
import TKUICard from 'tripkit-react/dist/card/TKUICard';
import OverviewChart from '../cities/OverviewChart';
import PTServicesChart from '../cities/PTServicesChart';
import ShareMicroView from '../cities/ShareMicroView';
import TKTransportOptions from 'tripkit-react/dist/model/options/TKTransportOptions';
import ModeLocation from 'tripkit-react/dist/model/location/ModeLocation';
import { TKUIModeLocationMarker } from 'tripkit-react/dist/map/TKUIMapLocations';
import classnames from 'classnames';
import ModeIdentifier from 'tripkit-react/dist/model/region/ModeIdentifier';
import PrivateTSPsView from '../cities/PrivateTSPsView';
import StateUrl from './StateUrl';
import TKUIWaitingRequest, { TKRequestStatus } from 'tripkit-react/dist/card/TKUIWaitingRequest';
import PTDetailsView, { Sections } from '../cities/PTDetailsView';
import RoutesView from '../cities/RoutesView';
import RouteDetailView, { defaultRouteColor } from '../cities/RouteDetailView';
import { Environment, TKAccountProvider } from 'tripkit-react';
import { SignInStatus, TKAccountContext } from 'tripkit-react/dist/account/TKAccountContext';
import TKAuth0AuthResponse from 'tripkit-react/dist/account/TKAuth0AuthResponse';
import TKUserAccount from 'tripkit-react/dist/account/TKUserAccount';

const cityStatsAppDefaultStyle = (theme: TKUITheme) => ({
    main: {
        width: '100%',
        height: '100%',
        ...genStyles.flex,
        ...theme.isDark && {
            '& .splitter': {
                background: black(1) + '!important',
                '& .line': {
                    background: white(1) + '!important'
                },
                '& .default-splitter:hover .line': {
                    background: white() + '!important'
                }
            }
        }
    },
    leftPanel: {
        ...genStyles.flex,
        height: '100%',
        boxSizing: 'border-box',
        '& > *': {
            ...genStyles.grow
        },
        background: white(0, theme.isDark)
    },
    topSplit: {
        ...genStyles.flex,
        padding: '10px',
        ...genStyles.grow,
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        // height: '1px', // Use this when not in a Split
        height: '100%',  // Use this if in a Split
        '& > *': {
            width: '50%'
        },
        '& > *:last-child': {
            marginLeft: '16px'
        }
    },
    chartSplit: {
        ...genStyles.flex,
        padding: '10px',
        boxSizing: 'border-box',
        background: white(0, theme.isDark),
        '&>*': {
            ...genStyles.grow
        },
        '&>*>*>*': {
            height: '100%'
        },
        height: '100%' // Use this if in a Split
    },
    experimentalFeatures: {
        ...theme.textWeightRegular
    }
});

type IStyle = ReturnType<typeof cityStatsAppDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> { }

const urlSearchParams = new URLSearchParams(window.location.search);
const apiKey = urlSearchParams.get('apiKey') ?? '790892d5eae024712cfd8616496d7317';

const config: TKUIConfig = {
    apiKey,
    theme: isDark => ({
        fontFamily: 'ProximaNova, sans-serif',
        colorPrimary: isDark ? '#23b15e' : '#008543'
    }),
    TKUIMapView: {
        props: (props: TKUIMapViewProps) => (
            {
                mapboxGlLayerProps: {
                    accessToken: "pk.eyJ1IjoibWdvbWV6bHVjZXJvIiwiYSI6ImNsbXJ1Y2c4MzA5ajgya3BncmlmOXdvZHYifQ.9ogoBJWetHkMG4V0QL9zPw",
                    style: props.theme.isLight ?
                        "mapbox://styles/mgomezlucero/ckjliu0460xxh1aqgzzb2bb34" :
                        "mapbox://styles/mgomezlucero/ckbmm6m0w003e1hmy0ksjxflm",
                    attribution: "<a href='http://osm.org/copyright' tabindex='-1'>OpenStreetMap</a>",
                    interactive: false
                },
                shouldFitMap: () => false
            }
        )
    },
    TKUICard: {
        styles: {
            main: overrideClass({
                boxShadow: 'none'
            })
        }
    }
};

const matchesFilter = (city: City, filter: string) => {
    const filterSplit = filter.toLowerCase().split(" ");
    return filterSplit.every((filterPart: string) =>
        city.name.toLowerCase().includes(filterPart)
    );
}

const matchesOperatorFilter = (operator: TSPInfo, filter: { textFilter?: string, checkedModes?: string[] }) => {
    const { textFilter, checkedModes } = filter;
    const matchesCheckedModes = !checkedModes || operator.modes.some(mode => checkedModes.includes(mode.mode));
    if (!matchesCheckedModes) {
        return false;
    }
    const filterSplit = textFilter?.toLowerCase().split(" ");
    const regexSearchTarget = [operator.name.toLowerCase()]
        .concat(operator.modes.map(mode => TransportUtil.modeIdToIconS(mode.mode).toLowerCase()))
        .concat(operator.integrations).concat(operator.integrations.includes("real_time") ? ["real-time", "realtime"] : [])
        .join(" ");
    let matchesRegex;
    try {
        matchesRegex = textFilter && regexSearchTarget.match(textFilter);
    } catch {
        matchesRegex = false;
    }
    const matchesTextFilter = !textFilter || filterSplit!.every((filterPart: string) =>
        operator.name.toLowerCase().includes(filterPart)
    ) || matchesRegex;
    if (!matchesTextFilter) {
        return false;
    }
    return true;
}

const matchesRoutesFilter = (route: RouteInfo, filter: { textFilter?: string, checkedModes?: string[], checkedShapeTypes?: ShapeTypes[] }) => {
    const { textFilter, checkedModes, checkedShapeTypes } = filter;
    const matchesCheckedModes = !checkedModes || checkedModes.includes(route.mode);
    if (!matchesCheckedModes) {
        return false;
    }
    const { id, routeName, mode, modeInfo, directions } = route;
    const shapeTypes = directions.map(dir => dir.shapeIsDetailed ? ShapeTypes.detailed : ShapeTypes.not_detailed);
    const matchesCheckedShapeTypes = !checkedShapeTypes || shapeTypes.some(shapeType => checkedShapeTypes.includes(shapeType));
    if (!matchesCheckedShapeTypes) {
        return false;
    }
    const filterSplit = textFilter
        ?.substring(textFilter.lastIndexOf("|") + 1, textFilter.length) // Hack to avoid indroducing a pipe to rule out the "text split" results, useful for the 'add to filter' feature.
        .toLowerCase().split(" ");
    const searchTargets = ([] as string[])
        .concat(routeName ? [routeName.toLowerCase()] : [])
        .concat(modeInfo ? [modeInfo.alt.toLowerCase()] : [])
        .concat(shapeTypes.map(shapeType => shapeType === ShapeTypes.detailed ? "is_detailed" : ShapeTypes.not_detailed))

    // Add space before and after the id for the ids regex to match exact ids.
    const regexSearchTarget = [` ${id.toLowerCase()}`].concat(searchTargets).join(" ");
    let matchesRegex;
    try {
        matchesRegex = textFilter && regexSearchTarget.match(textFilter.toLowerCase());
    } catch {
        matchesRegex = false;
    }

    const textSearchTarget = searchTargets.join(" ");
    const matchesTextFilter = !textFilter || filterSplit!.every((filterPart: string) =>
        textSearchTarget.includes(filterPart)
    );

    return matchesRegex || matchesTextFilter;
}

const applyFilter = (cities: City[], filter: string) => {
    return cities.filter(city => matchesFilter(city, filter));
}

let mapController: MapController | undefined;

export const integrationValues = ["routing", "real_time", "bookings", "payment"];
export type Integration = typeof integrationValues[number];

export interface IAppContext {
    loadingState: boolean;
    setLoadingState: (value: boolean) => void;
    selectedCity?: City;
    setSelectedCity: (value?: City) => void;
    cityFilter?: string;
    setCityFilter: (value?: string) => void;
    selectedMode?: ModeInfo;
    setSelectedMode: (value?: ModeInfo) => void;
    operatorFilter?: string;
    setOperatorFilter: (value?: string) => void;
    checkedModes?: string[];
    setCheckedModes: (value: string[]) => void;
    expandedModes?: string[];
    setExpandedModes: (value?: string[]) => void;
    checkedIntegrations?: Integration[];
    setCheckedIntegrations: (value?: Integration[]) => void;
    checkedShapeTypes?: ShapeTypes[];
    setCheckedShapeTypes: (value: ShapeTypes[]) => void;
    locations?: ModeLocation[];
    setLocations?: (value?: ModeLocation[]) => void;
    operators?: TSPInfo[];
    pTSection: Sections;
    setPTSection: (value: Sections) => void;
    preselectedOperator?: TSPInfo;
    setPreselectedOperator: (operator: TSPInfo | undefined) => void;
    selectedOperatorId?: string;
    setSelectedOperatorId: (operator: string | undefined) => void;
    preselectedRoute?: RouteInfo;
    setPreselectedRoute: (route: RouteInfo | undefined) => void;
    selectedRouteStopId?: string;
    setSelectedRouteStopId: (route: string | undefined) => void;
    setPreselectedDir: (route: string | undefined) => void;
    selectedRouteId?: string;
    setSelectedRouteId: (direction: string | undefined) => void;
    selectedRouteDirectionId?: string;
    setSelectedRouteDirectionId: (route: string | undefined) => void;
    routesFilter?: string;
    setRoutesFilter: (value?: string) => void;
    opRoutesFilter?: string;
    setOpRoutesFilter: (value?: string) => void;
    experimentalFeatures: boolean;
}

export const AppContext = React.createContext<IAppContext>({
    loadingState: false,
    setLoadingState: () => { },
    setSelectedCity: () => { },
    setCityFilter: () => { },
    setSelectedMode: () => { },
    setOperatorFilter: () => { },
    setRoutesFilter: () => { },
    setOpRoutesFilter: () => { },
    setCheckedModes: () => { },
    setExpandedModes: () => { },
    setCheckedIntegrations: () => { },
    setCheckedShapeTypes: () => { },
    pTSection: Sections.Operators,
    setPTSection: () => { },
    setPreselectedOperator: () => { },
    setSelectedOperatorId: () => { },
    setPreselectedRoute: () => { },
    setPreselectedDir: () => { },
    setSelectedRouteStopId: () => { },
    setSelectedRouteId: () => { },
    setSelectedRouteDirectionId: () => { },
    experimentalFeatures: false
});

let pTSectionGlobal: Sections = Sections.Operators;
let selectedOperatorIdGlobal: string | undefined = undefined;
let selectedRouteIdGlobal: string | undefined = undefined;

export enum ShapeTypes {
    detailed = "detailed",
    not_detailed = "not_detailed"
}

const CityStatsApp: React.FunctionComponent<IProps> = (props: IProps) => {
    const { classes, theme } = props;
    const [loadingState, setLoadingState] = useState<boolean>(true);
    const [cities, setCities] = useState<City[] | undefined>(undefined);
    const [selectedCity, setSelectedCity] = useState<City | undefined>(undefined);    // When defined, display just that polygon on map. Switch to a list when supporting multiple cities.
    const [preselectedCity, setPreselectedCity] = useState<City | undefined>(undefined); // Switch to a list when supporting multiple cities.
    const [cityFilter, setCityFilter] = useState<string | undefined>(undefined); // Then extend to something more complex.
    const filteredCities = cityFilter !== undefined ? applyFilter(cities ?? [], cityFilter) : cities;
    const [regionToOperators, setRegionToOperators] = useState<{ [key: string]: TSPInfo[] | null }>({});
    const [regionToRoutes, setRegionToRoutes] = useState<{ [key: string]: RouteInfo[] | null }>({});
    const [regionToInfo, setRegionToInfo] = useState<{ [key: string]: RegionInfo | null }>({});
    const [selectedMode, setSelectedMode] = useState<ModeInfo | undefined>(undefined);
    const [operatorFilter, setOperatorFilter] = useState<string | undefined>(undefined); // Then extend to something more complex.
    const [routesFilter, setRoutesFilter] = useState<string | undefined>(undefined); // Then extend to something more complex.
    const [opRoutesFilter, setOpRoutesFilter] = useState<string | undefined>(undefined); // Then extend to something more complex.
    const [preselectedOperator, setPreselectedOperator] = useState<TSPInfo | undefined>(undefined);
    const [selectedOperatorId, setSelectedOperatorId] = useState<string | undefined>(undefined);
    selectedOperatorIdGlobal = selectedOperatorId;
    const [preselectedRoute, setPreselectedRoute] = useState<RouteInfo | undefined>(undefined);
    const [preselectedDir, setPreselectedDir] = useState<string | undefined>(undefined);
    const [selectedRouteStopId, setSelectedRouteStopId] = useState<string | undefined>(undefined);
    const [selectedRouteId, setSelectedRouteId] = useState<string | undefined>(undefined);
    selectedRouteIdGlobal = selectedRouteId;
    const [selectedRouteDirectionId, setSelectedRouteDirectionId] = useState<string | undefined>(undefined);
    const [checkedModes, setCheckedModes] = useState<string[] | undefined>(undefined);
    const [checkedShapeTypes, setCheckedShapeTypes] = useState<ShapeTypes[] | undefined>(Object.values(ShapeTypes));
    const [expandedModes, setExpandedModes] = useState<string[] | undefined>(undefined);
    const [checkedIntegrations, setCheckedIntegrations] = useState<Integration[] | undefined>(undefined);
    const operators = selectedCity && (regionToOperators[selectedCity.regionCode] ?? undefined);
    const filteredOperators = operators?.filter(operator => matchesOperatorFilter(operator, { textFilter: operatorFilter, checkedModes }));
    const filteredOperatorsIds = filteredOperators?.map(op => op.id) ?? [];
    const routes = selectedCity && selectedMode?.identifier === ModeIdentifier.PUBLIC_TRANSIT_ID ? regionToRoutes[selectedCity.regionCode] ?? undefined : undefined;
    const filteredRoutes = routes?.filter(route => matchesRoutesFilter(route, { textFilter: routesFilter, checkedModes, checkedShapeTypes }));
    const filteredRoutesIds = filteredRoutes?.map(route => route.id) ?? [];
    let filteredOpRoutes = selectedCity && selectedMode?.identifier === ModeIdentifier.PUBLIC_TRANSIT_ID && selectedOperatorId ?
        regionToRoutes[selectedCity.regionCode]?.filter(route => route.operatorId === selectedOperatorId) ?? undefined : undefined;
    filteredOpRoutes = filteredOpRoutes?.filter(route => matchesRoutesFilter(route, { textFilter: opRoutesFilter, checkedModes, checkedShapeTypes }));
    const filteredOpRoutesIds = filteredOpRoutes?.map(route => route.id) ?? [];
    const [pTSection, setPTSection] = useState<Sections>(Sections.Operators);
    pTSectionGlobal = pTSection;
    const selectedOperator = useMemo(() => operators?.find(op => op.id === selectedOperatorId), [operators, selectedOperatorId]);
    const selectedRoute = useMemo(() => routes?.find(route => route.id === selectedRouteId), [routes, selectedRouteId]);
    const selectedRouteStop = useMemo(() =>
        selectedRoute?.directions.find(dir => dir.id === selectedRouteDirectionId)?.stops.find(stop => stop.stopCode === selectedRouteStopId),
        [selectedRoute, selectedRouteStopId]);
    MapController.theme = useMemo(() => theme, [theme]);
    const [experimentalFeatures, setExperimentalFeatures] = useState<boolean>(Environment.isDev() || Environment.isBeta());
    const [notification, setNotification] = useState<React.ReactNode | undefined>(undefined);

    useEffect(() => {
        if (selectedCity && selectedRoute) {
            TripGoApi.apiCallT('info/routeInfo.json', "POST", RouteInfo,
                {
                    region: selectedCity.regionCode,
                    operatorID: selectedRoute.operatorId,
                    routeID: selectedRoute.id
                })
                .then(route => {
                    const routes = regionToRoutes[selectedCity.regionCode]!;
                    const routesUpdate = routes!.slice();
                    routesUpdate[routes.indexOf(selectedRoute)] = route;
                    const regionToRoutesUpdate = { ...regionToRoutes };
                    regionToRoutesUpdate[selectedCity.regionCode] = routesUpdate;
                    setRegionToRoutes(regionToRoutesUpdate);
                })
        }
    }, [selectedRoute?.id, selectedCity]);
    const requestRegions = () => {
        return RegionsData.instance.requireRegions()
            .then(() => {
                setCities(RegionsData.instance.getCities());
                if (process.env.NODE_ENV === 'development') {
                    // onSelect(RegionsData.instance.getCities()![0]);  // For dev -> delete
                    // onSelect(RegionsData.instance.getCities()?.find(city => city.title === "The Hague, Netherlands"));  // For dev -> delete
                    // onSelect(RegionsData.instance.getCities()?.find(city => city.title === "Aberdeen, UK"));  // For dev -> delete
                    // onSelect(RegionsData.instance.getCities()?.find(city => city.title === "Aarhus, Denmark"));  // For dev -> delete
                    // onSelect(RegionsData.instance.getCities()?.find(city => city.title === "Melbourne, VIC, Australia"));  // For dev -> delete                    
                    // onSelect(RegionsData.instance.getCities()?.find(city => city.title === "Sydney, NSW, Australia"));  // For dev -> delete                    
                }
            });
    }
    const onRefreshCities = () => {
        setCities(undefined);
        RegionsData.reset();
        requestRegions();
    }
    useEffect(() => {
        mapAsync.then(map => {
            map.setViewport(LatLng.createLatLng(0, 0), 2);
            mapController = new MapController(map);
            mapController.addRouteClickListener(routeIDs => {
                // The simple / cleaner regex with just "pipes" suffers the problem of ids included in other ids. E.g. try filter 993 in Sydney, 
                // which matches routes with IDs 993, 993E and 2442_993. If click on route 993, then the 3 routes will be selected.
                // const routeIDsRegex = routeIDs.join("|");
                // Solution: add a space before and after each id, and also add the space to the target search string build in matchesRouteFilter 
                const routeIDsRegex = routeIDs.map(rID => ` ${rID} `).join("|");
                // const routeIDsRegex = routeIDs.map(rID => `^${rID} `).join("|");    // More strict / precise way
                if (pTSectionGlobal === Sections.Routes) {
                    setRoutesFilter(routeIDsRegex);
                } else if (selectedOperatorIdGlobal) {  // pTSectionGlobal === Sections.Operators
                    setOpRoutesFilter(routeIDsRegex);
                } else { // Other option is to not make paths clickable at this stage. Notice that mutiple routes, and from different operators, can be clicked at once.
                    setPTSection(Sections.Routes);
                    setRoutesFilter(routeIDsRegex);
                }
            });
            mapController.addRouteHoverListener(routeIDs => {
                if (selectedRouteIdGlobal) {
                    return;
                }
                mapController?.preselectRoutes(routeIDs);
            });
            mapController.addRouteDirectionClickListener(({ routeId, directionId }) => {
                setSelectedRouteDirectionId(directionId);
            });
            mapController.addRouteStopClickListener(stop => {
                setSelectedRouteStopId(stop.stopCode);
            })
        });
        requestRegions();
        // Enable / disable experimental features.
        window.addEventListener("keydown", (zEvent: any) => {
            if (zEvent.shiftKey && zEvent.metaKey && zEvent.key === ".") {
                setExperimentalFeatures((prevExperimentalFeatures) => !prevExperimentalFeatures);
                zEvent.preventDefault();
            }
        });
    }, []);
    const initalExperimentalFeaturesEffect = useRef(true);
    useEffect(() => {
        // Avoid notification in the first run of this useEffect for experimentalFeatures.
        if (initalExperimentalFeaturesEffect.current) {
            initalExperimentalFeaturesEffect.current = false;
            return;
        }
        setNotification(
            <div className={classes.experimentalFeatures}>
                Experimental features
                <div style={{ color: experimentalFeatures ? theme.colorSuccess : theme.colorError }}>{experimentalFeatures ? "enabled" : "disabled"}
                </div>
            </div>
        );
        setTimeout(() => setNotification(undefined), 3000);
    }, [experimentalFeatures])

    const requestOperators = () => {
        if (selectedCity && regionToOperators[selectedCity.regionCode] === undefined) {
            regionToOperators[selectedCity.regionCode] = null;  // To register that we are awaiting for response            
            TripGoApi.apiCall("/info/operators.json", NetworkUtil.MethodType.POST, {
                region: selectedCity.regionCode,
                full: true
            }).then(operatorsJSON => {
                const operators = Util.jsonConvert().deserializeArray(operatorsJSON, TSPInfo);
                const update = { ...regionToOperators };
                update[selectedCity.regionCode] = operators.sort((o1, o2) => o2.numberOfServices - o1.numberOfServices);
                RegionsData.instance.getRegionInfoP(selectedCity.regionCode)
                    .then(regionInfo => {
                        const regionInfoOpsById = regionInfo.operators.reduce((opsById, op) => {
                            opsById[op.id] = op;
                            return opsById;
                        }, {});
                        update[selectedCity.regionCode]?.
                            forEach(op => op.modes.
                                forEach(opMode => regionInfoOpsById[op.id]?.realTimeStatus === "CAPABLE" && opMode.integrations.push("real_time")));
                        setRegionToOperators(update);
                    });
            });
        }
    }

    const requestRegionInfo = () => {
        if (selectedCity && regionToInfo[selectedCity.regionCode] === undefined) {
            RegionsData.instance.getRegionInfoP(selectedCity.regionCode)
                .then(regionInfo => {
                    // Workaround since localIcon cames wrong: 'public-transport', but should be 'publicTransport'.
                    regionInfo.modes[ModeIdentifier.PUBLIC_TRANSPORT_ID] && (regionInfo.modes[ModeIdentifier.PUBLIC_TRANSPORT_ID].modeInfo.localIcon = "publicTransport");
                    const update = { ...regionToInfo };
                    // Workaround since 'pt_ltd' mode doesn't come in regionInfo.modes. 
                    const region = RegionsData.instance.getRegionByName(selectedCity.regionCode);
                    const ptLtdModes = region?.modes.filter(mode => TransportUtil.isSubMode(mode, 'pt_ltd'));
                    !regionInfo.modes['pt_ltd'] && ptLtdModes && ptLtdModes.length > 0 &&
                        (regionInfo.modes['pt_ltd'] =
                            Util.deserialize({
                                title: "Private transit",
                                modeInfo: {
                                    identifier: "pt_ltd",
                                    alt: "Private transit",
                                    localIcon: "shuttlebus"
                                },
                                specificModes: ptLtdModes.map(smode => {
                                    const modeIdentifier = RegionsData.instance.getModeIdentifier(smode)!;
                                    return ({
                                        title: modeIdentifier.title,
                                        modeInfo: {
                                            identifier: modeIdentifier.identifier,
                                            alt: modeIdentifier.title,
                                            localIcon: modeIdentifier.icon ?? "shuttlebus"
                                        }
                                    });
                                })
                            }, Mode));
                    update[selectedCity.regionCode] = regionInfo;
                    setRegionToInfo(update);
                });
        }
    }

    const requestRoutes = () => {
        if (selectedCity && regionToRoutes[selectedCity.regionCode] === undefined) {
            regionToRoutes[selectedCity.regionCode] = null;  // To register that we are awaiting for response            
            TripGoApi.apiCall("/info/routes.json", NetworkUtil.MethodType.POST, {
                region: selectedCity.regionCode,
                full: true,
                includeStops: false
            }).then((result: any[]) => {
                const routes = Util.jsonConvert().deserializeArray(result, RouteInfo) as RouteInfo[];
                const update = { ...regionToRoutes };
                update[selectedCity.regionCode] = routes;
                // .sort((o1, o2) => o2.numberOfServices - o1.numberOfServices);
                setRegionToRoutes(update);
            });
            RegionsData.instance.getRegionInfoP(selectedCity.regionCode)
                .then(regionInfo => {
                    const update = { ...regionToInfo };
                    update[selectedCity.regionCode] = regionInfo;
                    setRegionToInfo(update);
                });
        }
    }
    useEffect(() => {
        requestOperators();
        requestRegionInfo();
        requestRoutes();
    }, [selectedCity]);
    useEffect(() => {
        refreshRegionRoutesGeoJSON();
    }, [regionToRoutes, selectedCity, selectedMode]);
    useEffect(() => {
        refreshMapRoutesFilters();
    }, [operatorFilter, filteredOperatorsIds?.length, routesFilter, filteredRoutesIds?.length, filteredOpRoutesIds?.length, pTSection, checkedModes, checkedShapeTypes, selectedMode, selectedOperatorId]);
    useEffect(() => {
        mapController?.preselectOperator(preselectedOperator?.id);
    }, [preselectedOperator]);
    useEffect(() => {
        if (!preselectedRoute || preselectedDir === undefined) {
            mapController?.preselectRoute(preselectedRoute?.id);
        } else {
            mapController?.preselectRouteDirection(preselectedRoute.id, preselectedDir);
        }
    }, [preselectedRoute, preselectedDir, regionToRoutes]);
    useEffect(() => {
        mapController?.selectRoute(selectedRoute, selectedRouteDirectionId);
        if (!selectedRoute) {
            return;
        }
        mapAsync.then(map => {
            const mapBounds = map.getBounds();
            if (mapBounds && selectedRoute!.directions[0].shape?.some(latLng => MapUtil.inBBox(latLng, mapBounds))) {
                return;
            }
            const routeBounds = MapUtil.boundsFromLatLngArray(selectedRoute!.directions[0].shape!);
            map.fitBounds(routeBounds);
        })
    }, [selectedRoute?.id, selectedRouteDirectionId, selectedRoute?.directions[0]?.stops.length]);
    useEffect(() => {
        mapController?.selectRouteStop(selectedRouteStop);
    }, [selectedRouteStop]);
    useEffect(() => {
        // For the case of webapp load with route stop already set, to wait until markers are created after routeInfo results arrived.
        setTimeout(() => mapController?.selectRouteStop(selectedRouteStop), 500);
    }, [selectedRoute?.directions[0]?.stops.length]);

    const regionInfo = selectedCity && (regionToInfo[selectedCity.regionCode] ?? undefined);
    const region = selectedCity && RegionsData.instance.getRegionByName(selectedCity.regionCode);

    const [locations, setLocations] = useState<ModeLocation[] | undefined>(undefined);

    const chart = regionInfo && operators &&
        (!selectedMode ?
            <TKUICard doNotStack={true}>
                <OverviewChart
                    regionInfo={regionInfo}
                    operators={filteredOperators!}
                    checkedModes={checkedModes ?? []}
                    expandedModes={expandedModes ?? []}
                    checkedIntegrations={checkedIntegrations ?? []}
                />
            </TKUICard> :
            selectedMode?.identifier === ModeIdentifier.PUBLIC_TRANSIT_ID && pTSection === Sections.Operators && !selectedOperator ?
                <TKUICard doNotStack={true}>
                    <PTServicesChart
                        operators={filteredOperators!}
                        checkedModes={checkedModes ?? []}
                        checkedIntegrations={checkedIntegrations ?? []}
                    />
                </TKUICard> : undefined);

    const [showChart, setShowChart] = useState<boolean>(false);
    useEffect(() => {
        setTimeout(() => setShowChart(!!selectedCity && !!chart), 100);
    }, [selectedCity, !!chart]);
    const refreshRegionRoutesGeoJSON = () => {
        const regionRoutes = selectedCity && selectedMode?.identifier === ModeIdentifier.PUBLIC_TRANSIT_ID ? regionToRoutes[selectedCity.regionCode] : undefined;
        const routeFeatures = regionRoutes
            ?.reduce((features, route) => {
                const routeFeatures = route.directions.reduce((dirFeatures, dir) => {
                    const shapeFeature = dir.shape && MapUtil.latLngsToGeoJSONFeature(dir.shape);
                    if (shapeFeature) {
                        route.operatorId && (shapeFeature.properties!["operatorId"] = route.operatorId);
                        route.id && (shapeFeature.properties!["routeId"] = route.id);
                        // route.id && (shapeFeature["id"] = route.id);
                        route.mode && (shapeFeature.properties!["mode"] = route.mode);
                        const routeColor = route.routeColor ?? defaultRouteColor(theme);
                        shapeFeature.properties!["routeColorR"] = routeColor.red;
                        shapeFeature.properties!["routeColorG"] = routeColor.green;
                        shapeFeature.properties!["routeColorB"] = routeColor.blue;
                        dir.id !== undefined && (shapeFeature.properties!["dirId"] = dir.id);
                        dir.shapeIsDetailed !== undefined && (shapeFeature.properties!["shaypeType"] = dir.shapeIsDetailed ? ShapeTypes.detailed : ShapeTypes.not_detailed);
                        dirFeatures.push(shapeFeature);
                    }
                    return dirFeatures;
                }, [] as any);
                features.push(...routeFeatures);
                return features;
            }, [] as any);
        mapController?.setData(MapUtil.featuresToCollection(routeFeatures ?? []));
    }
    const refreshMapRoutesFilters = () => {
        mapController?.filterRoutes({
            operatorIDs: selectedOperatorId ? [selectedOperatorId] : pTSection === Sections.Operators ? filteredOperatorsIds : undefined,
            routeIDs: pTSection === Sections.Routes ? filteredRoutesIds :
                pTSection === Sections.Operators && selectedOperator ? filteredOpRoutesIds : undefined,
            modes: selectedMode?.identifier === 'cy_bic-s' ? [selectedMode.identifier] : checkedModes ?? [],
            shapeTypes: pTSection === Sections.Routes ? checkedShapeTypes : undefined
        });
    }
    const { map, mapAsync, onQueryUpdate } = useContext(RoutingResultsContext);
    const onSelectCity = city => {
        setSelectedCity(city);
        mapAsync.then(map => map.setViewport(city, 13));
    };
    const routingContext = useContext(RoutingResultsContext);
    const mapView = (
        <TKUIMapViewHelpers.TKStateProps>
            {/* {({ setMap, onViewportChange }) => */}
            {stateProps =>  // For now fully connect with the state, the see if can avoid connection, and, for instance, maitain the map in the AppContext
                <TKUIMapView
                    // setMap={setMap}
                    // onViewportChange={onViewportChange}
                    {...stateProps}
                    hideLocations={true}
                    transportOptions={region && Util.deserialize({
                        transportToOption: region.modes.map(mode => [mode, mode === selectedMode?.identifier ? 0 : 2])
                    }, TKTransportOptions)}
                    disableMapClick={selectedMode?.identifier !== 'cy_bic-s'}
                    // disableMapClick={true}
                    childrenThis={mapThis => selectedMode?.identifier === 'cy_bic-s' && locations?.map((loc, i) =>
                        routingContext.query?.to === loc ? null :
                            <TKUIModeLocationMarker
                                loc={loc}
                                onClick={() => mapThis.onClick?.(loc)}
                                key={i} />)
                    }
                >
                    {!selectedCity &&
                        <TGUICityLocations values={filteredCities} onSelect={onSelectCity} preselect={preselectedCity} onPreselect={setPreselectedCity} />}
                </TKUIMapView>
            }
        </TKUIMapViewHelpers.TKStateProps>
    );

    const appContextValue: IAppContext = {
        loadingState,
        setLoadingState,
        selectedCity,
        setSelectedCity: onSelectCity,
        cityFilter,
        setCityFilter,
        selectedMode,
        setSelectedMode,
        operatorFilter,
        setOperatorFilter,
        checkedModes,
        setCheckedModes,
        expandedModes,
        setExpandedModes,
        checkedIntegrations,
        setCheckedIntegrations,
        checkedShapeTypes,
        setCheckedShapeTypes,
        locations,
        setLocations,
        operators,
        pTSection,
        setPTSection,
        preselectedOperator,
        setPreselectedOperator,
        selectedOperatorId,
        setSelectedOperatorId,
        preselectedRoute,
        setPreselectedRoute,
        selectedRouteStopId,
        setSelectedRouteStopId,
        selectedRouteId,
        setSelectedRouteId,
        selectedRouteDirectionId,
        setSelectedRouteDirectionId,
        routesFilter,
        setRoutesFilter,
        opRoutesFilter,
        setOpRoutesFilter,
        setPreselectedDir,
        experimentalFeatures
    };

    /**
     * Accounts
     */
    const { status, login, logout } = useContext(TKAccountContext);
    (window as any).logout = logout;
    useEffect(() => {
        if (!accounts) {
            return;
        }
        console.log("Status: " + status);
        if (status === SignInStatus.signedOut) {
            login();
        }
        // Workaround to reset url after signin since Auth0 leaves a code as a url query param. See how to properly fix this.
        if (status === SignInStatus.signedIn) {
            window.history.replaceState(null, '', document.location.hash || "/");
        }
    }, [status]);

    if (accounts && status !== SignInStatus.signedIn) {
        return <TKUIWaitingRequest status={TKRequestStatus.wait} />;
    }

    return (
        <AppContext.Provider value={appContextValue}>
            <div className={classnames(classes.main, genClassNames.root)}>
                <Split
                    initialPrimarySize='400px'
                    minPrimarySize='400px'
                // minSecondarySize='50%'                    
                >
                    <div className={classes.leftPanel}>
                        {!selectedCity &&
                            <CitiesListView
                                values={filteredCities}
                                onSelect={onSelectCity}
                                preselect={preselectedCity}
                                onPreselect={setPreselectedCity}
                                filter={cityFilter}
                                onFilterChange={setCityFilter}
                                onRefreshCities={onRefreshCities}
                            />}
                        {selectedCity && !selectedMode &&
                            <StatsView
                                city={selectedCity}
                                onModeDetail={setSelectedMode}
                                operators={filteredOperators}
                                regionInfo={regionToInfo[selectedCity.regionCode] ?? undefined}
                                filter={operatorFilter}
                                onFilterChange={setOperatorFilter}
                                preselectOperator={preselectedOperator}
                                onPreselectOperator={setPreselectedOperator}
                                checkedModes={checkedModes}
                                setCheckedModes={setCheckedModes}
                                expandedModes={expandedModes}
                                setExpandedModes={setExpandedModes}
                                checkedIntegrations={checkedIntegrations}
                                setCheckedIntegrations={setCheckedIntegrations}
                                onRequestClose={() => {
                                    setSelectedCity(undefined);
                                    map?.setViewport(LatLng.createLatLng(0, 0), 2);
                                    setPTSection(Sections.Operators);
                                }}
                                onRefreshCity={() => {
                                    // TODO: this is error prone, replace with a simpler way, like reloading / re-rendering everything.
                                    // However for that I would need to have state on url so the web-app stays at the same view.

                                    // const operatorsUpdate = { ...regionToOperators };
                                    // Mutate on purpose to ensure requestOperators reads the updated value, without the need to wait for the state update.
                                    // Then need to create a copy for setting state to force refresh.
                                    const operatorsUpdate = regionToOperators;
                                    delete operatorsUpdate[selectedCity.regionCode];
                                    setRegionToOperators({ ...operatorsUpdate });
                                    RegionsData.reset();
                                    // const regionInfoUpdate = { ...regionToInfo };
                                    const regionInfoUpdate = regionToInfo;
                                    delete regionInfoUpdate[selectedCity.regionCode];
                                    setRegionToInfo({ ...regionInfoUpdate });
                                    // const routesUpdate = { ...regionToRoutes };
                                    const routesUpdate = regionToRoutes;
                                    delete routesUpdate[selectedCity.regionCode];
                                    setRegionToRoutes({ ...routesUpdate });
                                    requestOperators();
                                    requestRegionInfo();
                                    requestRoutes();
                                }}
                            />}
                        {selectedCity && selectedMode && selectedMode.identifier === ModeIdentifier.PUBLIC_TRANSIT_ID && !selectedOperatorId && !selectedRouteId &&
                            <PTDetailsView
                                mode={selectedMode}
                                operators={filteredOperators}
                                routes={filteredRoutes}
                                checkedModes={checkedModes ?? []}
                                regionInfo={regionToInfo[selectedCity.regionCode] ?? undefined}
                                operatorFilter={operatorFilter}
                                onOperatorFilterChange={setOperatorFilter}
                                onRequestClose={() => {
                                    setSelectedMode(undefined);
                                    setOperatorFilter(undefined);
                                    setRoutesFilter(undefined);
                                    setCheckedShapeTypes(Object.values(ShapeTypes));
                                }}
                            />}
                        {selectedCity && selectedMode && selectedMode.identifier === "pr" &&
                            <PrivateTSPsView
                                mode={selectedMode}
                                regionInfo={regionToInfo[selectedCity.regionCode] ?? undefined}
                                onModeDetail={setSelectedMode}
                                onRequestClose={() => {
                                    setSelectedMode(undefined);
                                    setOperatorFilter(undefined);
                                }}
                            />}
                        {selectedCity && selectedMode && selectedMode.identifier === 'cy_bic-s' && regionInfo &&
                            <ShareMicroView
                                mode={selectedMode}
                                // operators={filteredLocations}
                                regionInfo={regionInfo}
                                // checkedModes={checkedModes}                            
                                // regionInfo={regionToInfo[selectedCity.regionCode] ?? undefined}
                                // filter={operatorFilter}
                                // onFilterChange={setOperatorFilter}
                                // preselectOperator={preselectedOp}
                                // onPreselectOperator={setPreselectedOp}
                                onRequestClose={() => {
                                    setSelectedMode(undefined);
                                    setOperatorFilter(undefined);
                                    onQueryUpdate({ from: null, to: null });
                                }}
                            />}
                        {selectedCity && selectedOperator && !selectedRouteId &&
                            <TKUICard
                                title={selectedOperator.name}
                                subtitle={<span style={{ fontSize: '14px' }}>Use ↑ / ↓ to browse, ↵ to select, <span style={{ fontFamily: 'monospace' }}>esc</span> to go back</span>}
                                onRequestClose={() => {
                                    setSelectedOperatorId(undefined);
                                    setOpRoutesFilter(undefined);
                                }}
                            >
                                <RoutesView
                                    regionInfo={regionToInfo[selectedCity.regionCode] ?? undefined}
                                    routes={filteredOpRoutes}
                                    filter={opRoutesFilter}
                                    setFilter={setOpRoutesFilter}
                                />
                            </TKUICard>}
                        {selectedCity && selectedRoute &&
                            <RouteDetailView
                                route={selectedRoute}
                                onRequestClose={() => {
                                    setSelectedRouteId(undefined);
                                    setSelectedRouteDirectionId(undefined);
                                    setSelectedRouteStopId(undefined);
                                }}
                            />}
                    </div>
                    <Split
                        horizontal
                        initialPrimarySize='70%'
                        minPrimarySize={!chart ? '100%' : undefined}
                    >
                        {mapView}
                        <div className={classes.chartSplit}>
                            {chart}
                        </div>
                    </Split>
                </Split>
            </div>
            <TKUIWaitingRequest
                status={notification ? TKRequestStatus.success : undefined}
                message={notification}
                blocking={false}
                renderIcon={() => null}
            />
            <StateUrl />
            <TKUIWaitingRequest status={loadingState ? TKRequestStatus.wait : undefined} />
        </AppContext.Provider>
    );
};

const StyledCityStatsApp = withStyles(CityStatsApp, cityStatsAppDefaultStyle);

const auth0ClientId = "3d6DR9sAvpZ9TrkEjbeXzhesBy9ocrFB";
const auth0Domain = "tripgo.au.auth0.com";

const requestUserToken = (_auth0AccessToken: string) => {
    const authResponse = new TKAuth0AuthResponse();
    authResponse.userToken = "1234";
    return Promise.resolve(authResponse);
};
const requestUserProfile = _auth0user => {
    console.log(_auth0user);
    return Promise.resolve(new TKUserAccount());
};

const accounts = window.location.host.startsWith("closed");

export default () => {
    let app = <StyledCityStatsApp />;
    if (accounts) {
        app =
            <TKAccountProvider
                auth0Domain={auth0Domain}
                auth0ClientId={auth0ClientId}
                requestUserToken={requestUserToken}
                requestUserProfile={requestUserProfile}
            >
                {app}
            </TKAccountProvider>;
    }
    return (
        <TKRoot config={config}>
            {app}
        </TKRoot>
    );
};