import React, { Fragment } from 'react';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import TKUICard from 'tripkit-react/dist/card/TKUICard';
import RegionInfo, { Mode, SpecificMode } from 'tripkit-react/dist/model/region/RegionInfo';
import { genStyles, resetStyles } from 'tripkit-react';
import { filterInputStyleBuilder } from './CitiesListView';
import ModeInfo from 'tripkit-react/dist/model/trip/ModeInfo';
import TSPInfo from 'tripkit-react/dist/model/info/TSPInfo';
import PrivateTSPRow from './PrivateTSPRow';
import { isSubMode } from './OverviewChart';
import TransportUtil from 'tripkit-react/dist/trip/TransportUtil';

const privateTSPsViewPropsDefaultStyle = (theme: TKUITheme) => ({
    modeType: {
        background: black(4, theme.isDark),
        ...genStyles.flex,
        ...genStyles.alignCenter,
        padding: '8px 16px',
        '& img': {
            marginRight: '10px'
        }
    },
    modeDetails: {
        ...resetStyles.button,
        marginLeft: 'auto'
    },
    operators: {
        ...genStyles.flex,
        ...genStyles.column,
        height: '100%'
    },
    listPanel: {
        ...genStyles.scrollableY,
        ...genStyles.flex,
        ...genStyles.column,
        ...genStyles.grow,
        borderTop: '1px solid ' + black(4, theme.isDark)
    },
    topSplit: {
        ...genStyles.flex,
        padding: '10px',
        ...genStyles.grow,
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        // height: '1px', // Use this when not in a Split
        height: '100%'  // Use this if in a Split
    },
    chartSplit: {
        ...genStyles.flex,
        padding: '10px',
        boxSizing: 'border-box',
        '&>*': {
            ...genStyles.grow
        },
        '&>*>*>*': {
            height: '100%'
        },
        height: '100%' // Use this if in a Split
    },
    filterInput: filterInputStyleBuilder(theme),
    filterPanel: {
        margin: '16px 8px 8px 8px'
    },
    checkboxNode: {
        '& label, .rct-bare-label': {
            ...genStyles.flex,
            ...genStyles.alignCenter,
            ...genStyles.grow
        },
        '& .rct-node-icon': {
            padding: '0 4px!important'
        },
        '& .rct-title': {
            ...genStyles.grow
        },
    },
    iconExpand: {
        height: '14px',
        width: '14px',
        '& path': {
            fill: black(1, theme.isDark)
        }
    },
    iconExpandClose: {
        transform: 'rotate(270deg)'
    },
    iconCheckbox: {
        height: '16px!important',
        width: '16px!important',
        '& path': {
            fill: black(1, theme.isDark)
        }
    },
    iconHalfChecked: {
        opacity: '.5'
    },
    iconMode: {
        height: '20px',
        width: '20px!important'
    },
    subtitle: {
        lineHeight: '36px',
        marginTop: '5px'
    }
});

type IStyle = ReturnType<typeof privateTSPsViewPropsDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    mode: ModeInfo;
    regionInfo?: RegionInfo;
    onRequestClose?: () => void;
    onPreselectOperator?: (operator: TSPInfo | undefined) => void;
    onModeDetail?: (mode: ModeInfo) => void;
}

const PrivateTSPsView: React.FunctionComponent<IProps> =
    ({ mode, regionInfo, onModeDetail, onRequestClose, classes, theme }) => {
        return (
            <TKUICard
                title={mode.alt}
                subtitle={<div style={{ fontSize: '14px' }} className={classes.subtitle}>Use <span style={{ fontFamily: 'monospace' }}>esc</span> to go back</div>}
                onRequestClose={onRequestClose}
            >
                <div className={classes.listPanel}>
                    {regionInfo && Object.values(regionInfo.modes)
                        .filter(gMode => isSubMode(gMode.modeInfo.identifier!, mode.identifier!))
                        .map(gMode => (
                            <Fragment key={gMode.modeInfo.identifier}>
                                <div className={classes.modeType}>
                                    <img src={TransportUtil.getTransIcon(gMode.modeInfo, { onDark: theme.isDark })} className={classes.iconMode} />
                                    {gMode.title}
                                    {null &&
                                        <button
                                            className={classes.modeDetails}
                                            onClick={() => onModeDetail?.(gMode.modeInfo)}
                                        >
                                            Details
                                        </button>}
                                </div>
                                {(gMode.specificModes.length > 0 ? gMode.specificModes : [gMode] as (SpecificMode | Mode)[])
                                    .filter(sMode => isSubMode(sMode.modeInfo.identifier!, mode.identifier!) && (sMode instanceof SpecificMode || sMode.specificModes.length === 0))
                                    .map((operator, i) =>
                                        <PrivateTSPRow
                                            operator={operator}
                                            regionInfo={regionInfo}
                                            // preselected={operator === preselectOperator}                            
                                            // onMouseOver={() => {
                                            //     onPreselectOperator?.(operator);
                                            // }}
                                            // onMouseOut={() => {
                                            //     onPreselectOperator?.(undefined);
                                            // }}
                                            key={operator.modeInfo.identifier}
                                        />
                                    )}
                            </Fragment>
                        ))
                    }
                    {/* {regionInfo && (Object.values(regionInfo.specificModes) as (SpecificMode | Mode)[]).concat(Object.values(regionInfo.modes))
                                    .filter(sMode => isSubMode(sMode.modeInfo.identifier!, mode.identifier!) && (sMode instanceof SpecificMode || sMode.specificModes.length === 0))
                                    .map((operator, i) =>
                                        <PrivateTSPRow
                                            operator={operator}
                                            regionInfo={regionInfo}
                                            // preselected={operator === preselectOperator}                            
                                            // onMouseOver={() => {
                                            //     onPreselectOperator?.(operator);
                                            // }}
                                            // onMouseOut={() => {
                                            //     onPreselectOperator?.(undefined);
                                            // }}
                                            key={operator.modeInfo.identifier}
                                        />
                                    )} */}
                </div>
            </TKUICard>
        );
    }

export default withStyles(PrivateTSPsView, privateTSPsViewPropsDefaultStyle);