import React from 'react';
import { default as materialWithStyles } from '@material-ui/core/styles/withStyles';
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { Subtract } from 'utility-types';
import { genStyles } from 'tripkit-react';

const TooltipStyled = materialWithStyles({
    tooltip: {
        ...genStyles.fontS
    }
})(Tooltip);

const cursorHelp = {
    cursor: 'help'
};

const TGTooltip = (props: Subtract<TooltipProps, { children: React.ReactElement<any> }> & { children: React.ReactElement<any> | string; disabled?: boolean }) => {
    const { children, disabled, ...tooltipProps } = props;
    if (disabled) {
        return children as React.ReactElement<any>;
    }
    return <TooltipStyled
        placement={"top"}
        interactive
        enterDelay={500}
        leaveDelay={1}  // To avoid hiding and re-displaying when moving hover the tooltip (see interactive prop doc in https://material-ui.com/api/tooltip/)        
        {...tooltipProps}
        // If children is a string, surround it with a div and put the help cursor style. In any other case help cursor must
        // be put by the client component, outside, if appropriate. Notice that when, for instance, children is a button, then
        // we don't want a help cursor, but a pointer cursor.
        children={typeof children === 'string' ?
            <div style={cursorHelp}>{children}</div> :
            children as React.ReactElement<any>}
    />;
};

export default TGTooltip;