import React, { useContext, useEffect, useState } from 'react';
import { overrideClass, TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import City from 'tripkit-react/dist/model/location/City';
import TKUICard from 'tripkit-react/dist/card/TKUICard';
import RegionInfo, { Mode, SpecificMode } from 'tripkit-react/dist/model/region/RegionInfo';
import CheckboxTree, { Node } from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { ReactComponent as IconAngleDown } from "tripkit-react/dist/images/ic-angle-down.svg.js";
import { ReactComponent as IconChecked } from "../images/ic-checked.svg";
import { ReactComponent as IconUnchecked } from "../images/ic-unchecked.svg";
import TransportUtil from 'tripkit-react/dist/trip/TransportUtil';
import ModeInfo from 'tripkit-react/dist/model/trip/ModeInfo';
import Util from 'tripkit-react/dist/util/Util';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { resetStyles } from 'tripkit-react/dist/css/ResetStyle.css';
import TSPInfo from 'tripkit-react/dist/model/info/TSPInfo';
import { AppContext, Integration, integrationValues } from '../app/CityStatsApp';
import RefreshBtn from './RefreshBtn';
import { TRIPGO_API_CACHE_NAME } from '../cache/CacheUtil';
import ModeIdentifier from 'tripkit-react/dist/model/region/ModeIdentifier';
import TKUIButton, { TKUIButtonType } from 'tripkit-react/dist/buttons/TKUIButton';
import { useAppClasses } from '../app/app.jss';

const statsViewPropsDefaultStyle = (theme: TKUITheme) => ({
    main: {
        ...genStyles.flex,
        ...genStyles.column,
        height: '100%',
        padding: '16px'
    },
    iconMode: {
        height: '20px',
        width: '20px!important'
    },
    modeLabel: {
        ...genStyles.flex,
        ...genStyles.spaceBetween
    },
    modeDetails: {
        ...resetStyles.button
    },
    filterSection: {
        '& > div': {
            marginTop: '10px'
        },
        '&:not(:first-child)': {
            marginTop: '16px'
        }
    },
    cardSubtitle: {
        ...genStyles.flex,
        ...genStyles.spaceBetween,
        ...genStyles.alignCenter,
        margin: '5px -4px 0 0',
        height: '36px'
    }
});

type IStyle = ReturnType<typeof statsViewPropsDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    city: City;
    operators?: TSPInfo[];
    regionInfo?: RegionInfo;
    filter?: string;
    onFilterChange?: (filter: string) => void;
    onRequestClose?: () => void;
    preselectOperator?: TSPInfo;
    onPreselectOperator?: (operator: TSPInfo | undefined) => void;
    onModeDetail?: (mode: ModeInfo) => void;
    checkedModes?: string[];
    setCheckedModes: (update?: string[]) => void;
    expandedModes?: string[];
    setExpandedModes: (update?: string[]) => void;
    checkedIntegrations?: Integration[];
    setCheckedIntegrations: (update?: Integration[]) => void;
    onRefreshCity?: () => void;
}

type HNode = { mode: Mode | SpecificMode; hasDetails?: boolean; children?: HNode[] };

export const getModesHierarchy: (regionInfo: RegionInfo, experimentalFeatures?: boolean) => HNode[] =
    (regionInfo: RegionInfo, experimentalFeatures?: boolean) => {
        const modes = regionInfo.modes;
        const pTMode = Util.deepClone(modes[ModeIdentifier.PUBLIC_TRANSPORT_ID]);
        (pTMode.modeInfo as any)["identifier"] = ModeIdentifier.PUBLIC_TRANSIT_ID;
        const pTSpecificModes = pTMode.specificModes.concat(regionInfo?.modes['pt_ltd']?.specificModes ?? []);
        const prModes = Object.values(modes)
            .filter(mode => mode.modeInfo.identifier !== ModeIdentifier.PUBLIC_TRANSPORT_ID && mode.modeInfo.identifier !== ModeIdentifier.PUBLIC_TRANSIT_LIMITED_ID);
        const prMode = Util.deserialize({
            title: "Private transport",
            modeInfo: { identifier: 'pr', localIcon: "collect", alt: "Private transport" }
        }, Mode);
        return [
            {
                mode: pTMode,
                hasDetails: true,
                children: pTSpecificModes.map(smode => ({
                    mode: smode
                }))
            },
            {
                mode: prMode,
                hasDetails: true,
                children: prModes.map(smode => ({
                    mode: smode,
                    hasDetails: experimentalFeatures && smode.modeInfo.identifier === ModeIdentifier.BICYCLE_SHARE_ID
                }))
            }
        ];
    };

export const getInitCheckedModes = (regionInfo: RegionInfo) => {
    return getModesHierarchy(regionInfo)
        .reduce((smodeIDs, hMode) => {
            smodeIDs.push(hMode.mode.modeInfo.identifier!);
            hMode.children && smodeIDs.push(...hMode.children.map(children => children.mode.modeInfo.identifier!));
            return smodeIDs;
        }, [] as string[]);
}

export const getInitExpandedModes = (regionInfo: RegionInfo) => {
    return getModesHierarchy(regionInfo)
        .map(node => node.mode.modeInfo.identifier!);
}

export const getInitIntegrationValues = (operators: TSPInfo[] = [], regionInfo: RegionInfo) => {
    const modes = regionInfo.modes;
    return integrationValues.filter(integ =>
        operators.some(op => op.modes.some(opMode => opMode.integrations.includes(integ))) ||
        Object.values(modes).some(mode => mode.specificModes.some(smode => smode.integrations.includes(integ))));
}

const StatsView: React.FunctionComponent<IProps> =
    ({ city, operators, regionInfo, checkedModes, setCheckedModes, expandedModes, setExpandedModes, checkedIntegrations, setCheckedIntegrations, onModeDetail, onRefreshCity, onRequestClose, classes, theme }) => {
        const [modeNodes, setModeNodes] = useState<Node[] | undefined>(undefined);
        const appClasses = useAppClasses(theme);
        const { experimentalFeatures } = useContext(AppContext);
        useEffect(() => {
            if (!regionInfo) {
                return;
            }
            const hnodeToTreeNode = (hnode: HNode) => ({
                value: hnode.mode.modeInfo.identifier!,
                label: (hnode.hasDetails ?
                    <div className={classes.modeLabel}>
                        {hnode.mode.title}
                        <TKUIButton
                            type={TKUIButtonType.PRIMARY_LINK}
                            onClick={e => {
                                onModeDetail?.(hnode.mode.modeInfo);
                                e.stopPropagation();
                            }}
                            text={"Details"}
                            styles={{
                                link: overrideClass({
                                    color: theme.colorPrimary + '!important'
                                })
                            }}
                        />
                    </div> : hnode.mode.title
                ),
                icon: <img src={TransportUtil.getTransIcon(hnode.mode.modeInfo, { onDark: theme.isDark })} className={classes.iconMode} style={hnode.mode.modeInfo.identifier === 'pr' ? { padding: '2px' } : undefined} />,
                className: appClasses.checkboxNode,
                children: hnode.children?.map(hnodeToTreeNode)
            })
            const modesHierarchy = getModesHierarchy(regionInfo, experimentalFeatures);
            const modeNodes = modesHierarchy.map(hnodeToTreeNode);
            setModeNodes(modeNodes);
            if (checkedModes === undefined) {
                setCheckedModes(getInitCheckedModes(regionInfo));
            }
            if (expandedModes === undefined) {
                setExpandedModes(getInitExpandedModes(regionInfo));
            }
            if (checkedIntegrations === undefined) {
                setCheckedIntegrations(getInitIntegrationValues(operators, regionInfo));
            }
        }, [regionInfo, theme, experimentalFeatures]);    // trigger effect on theme update (e.g. dark / light change) since appClasses.checkboxNode also updates (classnames generated by jss change).
        const integrationNodes = integrationValues.map(integration => ({
            value: integration,
            label: Util.toFirstUpperCase(integration.replace("_", "-")),
            className: appClasses.checkboxNode
        }))
        const onlyLeafCheckboxes = false;
        return (
            <TKUICard
                title={city.name}
                subtitle={
                    <div className={classes.cardSubtitle}>
                        <span style={{ fontSize: '14px' }}>Use <span style={{ fontFamily: 'monospace' }}>esc</span> to go back</span>
                        {onRefreshCity &&
                            <RefreshBtn
                                actions={[
                                    {
                                        label: "Refresh this city",
                                        handler: async () => {
                                            const cache = await caches.open(TRIPGO_API_CACHE_NAME);
                                            (await cache.keys()).forEach(request => {
                                                request.url.match("regionInfo.json|operators.json|routes.json|routeInfo.json") &&
                                                    request.url.match(city.regionCode) &&
                                                    cache.delete(request);
                                            });
                                            onRefreshCity();
                                        }
                                    },
                                    {
                                        label: "Refresh all",
                                        handler: async () => {
                                            const found = await caches.delete(TRIPGO_API_CACHE_NAME);
                                            found && onRefreshCity();
                                        }
                                    }
                                ]}
                                waiting={(operators === undefined || regionInfo === undefined) ? true : undefined}
                                tooltip={"Refresh data for this city. Right click to get more refresh options"}
                            />
                        }
                    </div>
                }
                onRequestClose={() => {
                    onRequestClose?.();
                    setCheckedModes(undefined);
                    setExpandedModes(undefined);
                    setCheckedIntegrations(undefined);
                }}
            >
                <div className={classes.main}>
                    {regionInfo && modeNodes &&
                        <div className={classes.filterSection}>
                            Transport Type
                            <CheckboxTree
                                nodes={modeNodes}
                                checked={checkedModes}
                                expanded={expandedModes}
                                onCheck={setCheckedModes}
                                onExpand={setExpandedModes}
                                onlyLeafCheckboxes={onlyLeafCheckboxes}
                                checkModel={'all'}
                                expandOnClick={true}
                                onClick={() => { }}
                                // It seems that icons are created on CheckboxTree mount, so style updates (e.g. on theme change, as dark / light) 
                                // do not apply (appClasses.iconHalfChecked in this case). That's why I avoid styling icons this way, but did
                                // with css selectors 'below' checkboxNode (see app.jss.ts). Couldn't do for halfCheck though, so it doesn't work
                                // just after a dark / light change.
                                icons={{
                                    expandOpen: <IconAngleDown />,
                                    expandClose: <IconAngleDown />,
                                    check: <IconChecked />,
                                    halfCheck: <IconChecked className={appClasses.iconHalfChecked} />,
                                    uncheck: <IconUnchecked />
                                }}
                            />
                        </div>}
                    {regionInfo &&
                        <div className={classes.filterSection}>
                            Integration
                            <CheckboxTree
                                nodes={integrationNodes}
                                checked={checkedIntegrations}
                                onCheck={checked => setCheckedIntegrations(checked)}
                                onClick={() => { }}
                                icons={{
                                    expandOpen: <IconAngleDown />,
                                    expandClose: <IconAngleDown />,
                                    check: <IconChecked />,
                                    halfCheck: <IconChecked />,
                                    uncheck: <IconUnchecked />,
                                    leaf: null,
                                    parentOpen: null,
                                    parentClose: null
                                }}
                            />
                        </div>}
                </div>
            </TKUICard>
        );
    }

export default withStyles(StatsView, statsViewPropsDefaultStyle);