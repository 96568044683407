import React, { useState } from 'react';
import { TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { black, TKUITheme, white } from 'tripkit-react/dist/jss/TKUITheme';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { ReactComponent as IconSpin } from '../images/ic-refresh.svg';
import classnames from 'classnames';
import { resetStyles } from 'tripkit-react/dist/css/ResetStyle.css';
import TKUITooltip from 'tripkit-react/dist/card/TKUITooltip';
import TGTooltip from '../../../common/TGTooltip';

const refreshBtnDefaultStyle = (theme: TKUITheme) => ({
    main: {
        ...resetStyles.button,
        width: '36px',
        height: '36px',
        border: '1px solid ' + black(3, theme.isDark),
        ...genStyles.borderRadius(6),
        padding: '7px',
        '& svg': {
            height: '20px',
            width: '20px'
        },
        '& path': {
            fill: black(theme.isDark ? 1 : 0, theme.isDark)
        }
    },
    tooltip: {
        '& .rc-tooltip-arrow': {
            display: 'none'
        },
        '& .rc-tooltip-inner': {
            boxShadow: !theme.isDark ?
                '0 0 4px 0 rgba(0,0,0,.2), 0 6px 12px 0 rgba(0,0,0,.08)!important' :
                '0 0 4px 0 rgba(255,255,255,.2), 0 6px 12px 0 rgba(255,255,255,.08)!important',
        }
    },
    spin: {
        ...genStyles.animateSpin
    },
    actionMenu: {
        background: white(0, theme.isDark),
        padding: '5px',
        boxShadow: theme.isLight ?
            '0 0 4px 0 rgba(0,0,0,.2), 0 6px 12px 0 rgba(0,0,0,.08)' :
            '0 0 4px 0 rgba(255,255,255,.2), 0 6px 12px 0 rgba(255,255,255,.08)',
        ...genStyles.borderRadius(4)
    },
    actionItem: {
        ...genStyles.flex,
        ...genStyles.alignCenter,
        padding: '5px',
        cursor: 'pointer',
        color: black(0, theme.isDark),
        '&:hover': {
            color: '#00be5e!important'
        }
    }
});

type IStyle = ReturnType<typeof refreshBtnDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    waiting?: boolean;
    actions: { label: string, handler: () => void }[];
    tooltip?: string;
}

const RefreshBtn: React.FunctionComponent<IProps> =
    ({ actions, waiting, tooltip, classes }) => {
        const [showMenu, setShowMenu] = useState<boolean>(false);
        const button = (
            <button className={classnames(classes.main)}
                onClick={() => {
                    actions[0]!.handler();
                }}
                onContextMenu={e => {
                    setShowMenu(true);
                    e.preventDefault && e.preventDefault(); // Since safari on iOS fails saying preventDefault is not defined.
                }}
            >
                <IconSpin className={waiting ? classes.spin : undefined} />
            </button>
        );
        return (
            <TKUITooltip
                overlay={
                    <div className={classes.actionMenu}>
                        {actions.map(action =>
                            <div
                                onClick={() => {
                                    action.handler();
                                }}
                                className={classes.actionItem}
                                key={action.label}
                            >
                                {action.label}
                            </div>)}

                    </div>
                }
                arrowContent={null}
                arrowColor={"transparent"}
                placement={"bottomLeft"}
                visible={showMenu}
                onVisibleChange={(visible?: boolean) => !visible && setShowMenu(false)}
                className={classes.tooltip}
            >
                {tooltip && !showMenu ?
                    <TGTooltip title={tooltip} placement="right" enterDelay={1000}>
                        {button}
                    </TGTooltip> :
                    button
                }

            </TKUITooltip>
        );
    }

export default withStyles(RefreshBtn, refreshBtnDefaultStyle);